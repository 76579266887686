import React from 'react';
import Titulo from '../../Textos/Titulo';
import TiposPedido from '../../../util/typesTipoPedido';

export default function TituloPedidoConcluido({ pedido }) {


      const defineTitulo = () => {
            switch (pedido.tipoPedido.id) {
                  case TiposPedido.Recebimento:
                        return (
                              <>
                                    Estamos processando sua <br />
                                    ordem de pagamento no valor de:
                              </>
                        )
                  case TiposPedido.Venda:
                  case TiposPedido.Remessa:
                  case TiposPedido.VendaOuro:
                  case TiposPedido.SeguroViagem:
                        return (
                              <>
                                    Estamos aguardando o <br />
                                    pagamento de:
                              </>
                        )

                  default:
                        return (
                              <>
                                    Estamos processando seu pedido <br />
                                    Valor final que irá receber é de:
                              </>
                        )
            }
      }

      return (defineTitulo());
}