import { useState, useEffect, useRef } from "react";
import { CampoTexto } from "../../../components/index";
import {
  saveNew,
  createPagadorRecebedorByIDClienteIDpaisIDTipo,
} from "../../../api/RecebedorEstrangeiro";
import { Button, Grid } from "@mui/material";
import useAlert from "../../../hooks/useAlert";
import useStyles from "../styles";
import Titulo from "../../../components/Textos/Titulo";

export default function New({
  idCliente,
  idPais,
  idTipoPagadorRecebedor,
  onSetNewPagadorRecebedor,
  setError,
}) {
  const topo = useRef();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyles();
  const [beneficiario, setBeneficiario] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await createPagadorRecebedorByIDClienteIDpaisIDTipo(
      idCliente,
      idPais,
      idTipoPagadorRecebedor
    );

    setBeneficiario(response.data);
  };

  const handlerChangePropriedade = (e) => {
    const newValue = beneficiario.propriedades.map((c) => {
      if (c.descricao === e.target.name)
        return { ...c, ["value"]: e.target.value };
      else return c;
    });

    setBeneficiario({ ...beneficiario, ["propriedades"]: newValue });
  };

  const handleChange = (e) => {
    setBeneficiario({ ...beneficiario, [e.target.name]: e.target.value });
  };

  const handlerSave = async (event, item) => {
    event.preventDefault();
    setError((prevState) => "");

    const response = await saveNew(item);
    if (!response.errorMessage) {
      onSetNewPagadorRecebedor(response.data);
    } else {
      setError((prevState) => response.errorMessage);
    }
  };

  return (
    <>
      <Alert />
      <Titulo
        ref={topo}
        sx={{
          fontSize: "20px",
          mb: 1,
          mt: 2,
          pl: 1,
          borderBottom: "1px solid #ddd",
          paddingBottom: 2,
        }}
        label="Novo beneficiário"
      ></Titulo>
      <Grid container rowSpacing={2} sx={{ p: 1 }}>
        {beneficiario && (
          <Grid sx={{ margin: "auto" }} item xs={12}>
            <CampoTexto
              fullWidth={true}
              autoFocus
              required
              margin="normal"
              handlerChange={handleChange}
              name="nome"
              size="small"
              value={beneficiario.nome}
              enable={true}
              label="Nome"
              isError={false}
            />
          </Grid>
        )}

        {beneficiario &&
          beneficiario.propriedades &&
          beneficiario.propriedades.map((b, i) => (
            <Grid sx={{ margin: "auto" }} item xs={12} key={i}>
              <CampoTexto
                fullWidth={true}
                required={b.obrigatorio}
                handlerChange={handlerChangePropriedade}
                name={b.descricao}
                maximoDigitos={b.maxLength}
                size="small"
                value={b.value}
                enable={true}
                label={b.label !== null ? b.label : b.descricao}
              />
            </Grid>
          ))}
        {beneficiario && (
          <Grid item xs={12}>
            <Button
              style={{ marginTop: 25 }}
              variant="contained"
              fullWidth
              disabled={
                beneficiario?.idPais === 0 ||
                beneficiario?.idTipoPagadorRecebedor === 0
              }
              type="submit"
              onClick={(event) => handlerSave(event, beneficiario)}
            >
              Confirmar
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
