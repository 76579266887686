import { useEffect, useState } from "react";
import { getDocumentos } from "../../../api/Cadastro";
import CampoDocumento from "../../Campos/CampoDocumento";
import useAlert from "../../../hooks/useAlert";
import Subtitulo from "../../Textos/Subtitulo";
import useStyle from "./styles";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";

export default function Documento({
  setDados,
  user,
  idPerfilLimiteOperacional,
  isLoading,
}) {
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const [documentos, setDocumentos] = useState([]);
  const classes = useStyle();
  useEffect(() => {
    init(user.id, idPerfilLimiteOperacional);
  }, [user, idPerfilLimiteOperacional]);

  const init = async (idCliente, idPerfilLimiteOperacional) => {
    try {
      const response = await getDocumentos(
        idCliente,
        idPerfilLimiteOperacional
      );
      setDocumentos(response);
      setDados(response);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  const handlerChange = (doc) => {
    const newValue = documentos.map((d) => {
      if (d.idTipoDocumento === doc.idTipoDocumento) {
        return { ...doc, idCliente: user.id };
      }

      return { ...d, idCliente: user.id };
    });

    setDocumentos(newValue);
    setDados(newValue);
  };


  const handlerDeleteImage = (doc) => {
    if (doc) {
      let newDoc = documentos.map((d) => {
        if (d.tipoDocumento === doc.tipoDocumento) {
          d = doc;
        }
        return d;
      })

      setDocumentos((prev) => [...newDoc]);
      setDados(newDoc);
    }
  }

  return (
    <>
      <Alert />
      {isLoading ? (
        <Skeleton animation="wave" width={"10%"} height={30} sx={{ ml: 4 }} />
      ) : (
        <Subtitulo sx={{ ml: 3 }} color="primary" label="Anexo de Documentos" />
      )}

      <div className={classes.box}>
        {documentos &&
          documentos.map((d, index) => (
            <CampoDocumento
              key={index}
              doc={d}
              handlerDeleteImage={handlerDeleteImage}
              setData={handlerChange}
              isLoading={isLoading}
            />
          ))}
      </div>
    </>
  );
}
