import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CampoTexto from "../Campos/CampoTexto";
import CampoDocumentoIdentificacao from "../Campos/CampoDocumentoIdentificacao";
import CampoData from "../Campos/CampoData";
import Seletor from "../Seletor";

import { Style } from "./styles";
import { Card, Typography, useTheme } from "@mui/material";
import Subtitulo from "../Textos/Subtitulo";
import format from "date-fns/format";
import { useSeguro } from "../../store/seguroViagem";
import useAlert from "../../hooks/useAlert";

const sexos = [
  {
    id: 1,
    descricao: "Masculino",
  },
  {
    id: 2,
    descricao: "Feminino",
  },
  {
    id: 3,
    descricao: "Não Informado",
  },
];

export default function PassageiroItem({ passageiro, onChange, index }) {
  const classes = Style();

  const { Alert } = useAlert();

  const { updatePassageiro } = useSeguro();

  const [formValues, setFormValues] = useState({
    nome: passageiro?.nome || "",
    sobrenome: passageiro?.sobrenome || "",
    numeroDocumento: passageiro?.numeroDocumento || "",
    dataNascimento:
      passageiro?.dataNascimento || format(Date.now(), "yyyy/MM/dd"),
    idSexo: passageiro?.idSexo || 1,
    idade: passageiro?.idade
    // "tipoDocumento": 0,
    // "email": "",
    // "telefone": "",
    // "endereco": "",
    // "codigoPostal": "",
    // "cidade": "",
    // "estado": "",
    // "paisDomicilio": "",
    // "contato": "",
    // "telefoneContato": ""
  });

  const handleInputChange = (fieldName, value) => {
    const newObj = {
      ...formValues,
      [fieldName]: value,
    };

    setFormValues((prevState) => ({ ...newObj }));

    updatePassageiro(newObj, index);
  };

  const handlerChangeSelect = (fieldName, value) => {
    const newObj = {
      ...formValues,
      [fieldName]: value,
    };

    setFormValues((prevState) => ({ ...newObj }));

    updatePassageiro(newObj, index);
  };

  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    }
    if (value.includes("/")) {
      return value.replaceAll("/", "-");
    } else {
      return value;
    }
  };

  const handlerChangeData = (e) => {

    const newObj = {
      ...formValues,
      dataNascimento: e.target.value,
    };

    setFormValues((prevState) => ({ ...newObj }));

    updatePassageiro(newObj, index);
  };

  return (
    <>
      <Alert />
      <Box sx={{ flexGrow: 1 }}>
        <Card
          sx={{ border: "1px solid #ccc", background: "#fcfcfc", mb: 4 }}
          className={classes.Card}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", pr: 2, pt: 2 }}>
            <Box>
              <Typography
                sx={{ pl: 2, display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    background: "black",
                    width: "25px",
                    color: "white",
                    p: 0.2,
                    borderRadius: "50%",
                    textAlign: "center",
                  }}
                >
                  <b>{index + 1}º</b>
                </Typography>
                <Subtitulo sx={{ ml: 1 }}> Passageiro -  <strong>Idade:</strong>  {passageiro.idade}</Subtitulo>
              </Typography>
            </Box>

          </Box>
          <Grid container spacing={1} rowSpacing={3} className={classes.Container}>
            <Grid item xs={6}>
              <CampoTexto
                id="outlined-required"
                variant="filled"
                size="small"
                fullWidth={true}
                name="nome"
                label="Nome"
                value={formValues.nome}
                handlerChange={(e) => handleInputChange("nome", e.target.value)}
                enable={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CampoTexto
                id="outlined-required"
                variant="filled"
                size="small"
                fullWidth={true}
                name="sobrenome"
                label="Sobrenome"
                value={formValues.sobrenome}
                handlerChange={(e) =>
                  handleInputChange("sobrenome", e.target.value)
                }
                enable={true}
              // isLoading={isLoading}
              //enable={p.enable}
              />
            </Grid>
            <Grid item xs={4}>
              <CampoDocumentoIdentificacao
                size="small"
                name="numeroDocumento"
                value={formValues.numeroDocumento}
                handlerChange={(e) =>
                  handleInputChange("numeroDocumento", e.target.value)
                }
                idTipoDocumento={1}
              />
            </Grid>
            <Grid item xs={4}>
              <CampoData
                size="small"
                label="Data de nascimento"
                fullWidth={true}
                name="dataNascimento"
                value={formatDateValueInput(formValues.dataNascimento)}
                onChange={handlerChangeData}
                required={true}
                enable={true}
              />
            </Grid>
            <Grid item xs={4}>
              <Seletor
                setSelect={(e) => handlerChangeSelect("idSexo", e)}
                list={sexos}
                size="small"
                label="Sexo"
                fullWidth={true}
                value={formValues.idSexo}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
}
