import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DetalhePedidoCambio from "../DetalhePedidoCambio";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useStyles from "./style";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import { useQuery } from "react-query";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

import {
  DETALHEPEDIDOCOMERCIAL,
  DETALHEPEDIDOTURISMO,
} from "../../util/typesReactQuery";
import { format } from "date-fns";
import TiposPedido from "../../util/typesTipoPedido";
import { GetPedidoByID } from "../../api/PedidoTurismo";
import { getPedidoComercialByID } from "../../api/PedidoComercial";
import { GetPedidoOuro } from "../../api/PedidoTurismo";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Panels = {
  PedidosList: 0,
  detalhesPedido: 1,
};

export const PedidoCambio = ({
  isLoadingPedidos,
  isShowButtonMore,
  pedidos,
  habilitaVisualizarDetalhes,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  const { Alert, showAlertError } = useAlert();
  const [idDetalhes, setIDDetalhes] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [detalhePedido, setDetalhePedido] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [panel, setPanel] = useState(Panels.PedidosList);

  const hendlerChangePanel = (id) => (event) => {
    setPanel(1);
  };

  const hendlerChangePedidos = (id) => (event) => {
    isShowButtonMore(true);
    setPanel(0);
  };

  const { refetch: refetchDetalhePedidoComercial, isFetching: isLoadingDetalheComercial } = useQuery(
    [DETALHEPEDIDOCOMERCIAL, idDetalhes],
    () => getPedidoComercialByID(user?.id, idDetalhes ?? 0),
    {
      onSuccess: (data) => {
        if (!data && !!!data.data) {
          showAlertError(
            "Ops, algo deu errado: Não foi possivel encontrar nenhum pedido - R4D0C "
          );
        }

        setDetalhePedido(data.data);
      },
      onError: (error) => {
        showAlertError(`Ops, algo deu errado: ${error} - R7EB9`);
      },
      onSettled: () => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: refetchDetalhePedidoOuro, isFetching: isLoadingDetalheOuro } = useQuery(
    [DETALHEPEDIDOCOMERCIAL, idDetalhes],
    () => GetPedidoOuro(user?.id, idDetalhes ?? 0),
    {
      onSuccess: (data) => {
        if (!data && !!!data.data) {
          showAlertError(
            "Ops, algo deu errado: Não foi possivel encontrar nenhum pedido - R4D0C "
          );
        }

        setDetalhePedido(data.data);
      },
      onError: (error) => {
        showAlertError(`Ops, algo deu errado: ${error} - R7EB9`);
      },
      onSettled: () => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: refetchDetalhePedidoTurismo, isFetching: isLoadingDetalheTurismo } = useQuery(
    [DETALHEPEDIDOTURISMO, idDetalhes],
    () => GetPedidoByID(user?.id, idDetalhes),
    {
      onSuccess: (data) => {
        if (!data && !!!data.data) {
          showAlertError(
            "Ops, algo deu errado: Não foi possivel encontrar nenhum pedido - RD003 "
          );
        }

        setDetalhePedido(data.data);
      },
      onError: (error) => {
        showAlertError(`Ops, algo deu errado: ${error} - R46D8`);
      },
      onSettled: () => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (idDetalhes > 0) {
      let todosPedidos = new Array(0).concat(...pedidos);

      let pedidoSelecionado = todosPedidos?.find(
        (pedido) => pedido?.id === parseInt(idDetalhes)
      );

      let tipoPedido = pedidoSelecionado?.tipoPedido?.id;
      setIsLoading(true);

      if (
        tipoPedido === TiposPedido.CompraEspecie ||
        tipoPedido === TiposPedido.VendaEspecie
      ) {
        refetchDetalhePedidoTurismo();
        return;
      }

      if (tipoPedido === TiposPedido.VendaOuro) {
        refetchDetalhePedidoOuro();
        return;
      }

      refetchDetalhePedidoComercial();
    }
  }, [idDetalhes]);

  const handlerOpenDetails = (event, id) => {
    event.preventDefault();
    isShowButtonMore(false);
    setIDDetalhes((prevState) => id);
    setOpenDetails(true);
  };

  const PedidoTipo = ({ tipoPedido }) => {
    switch (tipoPedido?.id) {
      case TiposPedido.VendaEspecie:
        return "Moeda(s) que Comprei";

      case TiposPedido.CompraEspecie:
        return "Moeda(s) que Vendi";

      case TiposPedido.VendaOuro:
        return "Barra(s) de Ouro que comprei";

      case TiposPedido.Remessa:
        return "Moeda(s) que exportei";

      case TiposPedido.Recebimento:
        return "Valores que recebi do exterior";

      default:
        return tipoPedido.descricao;
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          margin: "auto !important",
          alignItems: "center !important",
        }}
      >
        {panel === Panels.PedidosList && (
          <Grid item xs={12}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>

              {!isLoadingPedidos &&
                pedidos &&
                pedidos?.map((page) =>
                  page?.map((pedido) => (
                    <div
                      key={pedido?.id}
                      onClick={(e) => handlerOpenDetails(e, pedido?.id)}
                    >
                      <ListItem
                        // onClick={(e) => handlerOpenDetails(e, pedido.id)}
                        id={Panels.detalhesPedido.toString()}
                        onClick={hendlerChangePanel(Panels.detalhesPedido)}
                        className={classes.PedidoCambio}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {" "}
                            <PaidOutlinedIcon />{" "}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline", fontWeight: "bold" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                #{pedido?.id}
                              </Typography>
                              <br></br>

                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <PedidoTipo tipoPedido={pedido?.tipoPedido} />
                                {/* {pedido.tipoPedido.descricao} */}
                              </Typography>

                              <Typography
                                component="span"
                                variant="subtitle"
                                sx={{
                                  mt: 1,
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                color="text.secondary"
                              >
                                <br></br>
                                <AccessTimeIcon
                                  fontSize="small"
                                  sx={{ mr: 1 }}
                                />
                                {format(
                                  new Date(pedido.dataCadastro),
                                  "dd/MM/yyyy"
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <ListItemText
                          sx={{
                            display: "inline",
                            textAlign: "end",
                          }}
                        >
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body1"
                              color="text.primary"
                            >
                              R$ {pedido.valorTotalMoedaNacional}
                            </Typography>

                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="text.primary"
                              sx={{ fontWeight: 500 }}
                              style={{
                                padding: 1,
                                borderRadius: 12,
                                color:
                                  pedido.status.descricao !== "Cancelado"
                                    ? "green"
                                    : "red",
                                background:
                                  pedido.status.descricao !== "Cancelado"
                                    ? "rgba(206, 255, 212, 0.5)"
                                    : "rgba(255, 206, 206, 0.5)",

                                fontWeight: "500",
                              }}
                              align="right"
                            >
                              <br></br>
                              {pedido.status.descricao}
                            </Typography>
                          </React.Fragment>
                        </ListItemText>

                        <ListItemAvatar
                          alt="ver detalhes"
                          sx={{
                            ml: 3,

                            cursor: "pointer",
                            minWidth: "0 !important",
                          }}
                        >
                          <ArrowForwardIosIcon
                            onClick={(e) => handlerOpenDetails(e, pedido?.id)}
                            color="text.secondary"
                            sx={{ fontSize: 14 }}
                          />
                        </ListItemAvatar>
                      </ListItem>
                    </div>
                  ))
                )}
            </List>
          </Grid>
        )}
      </Grid>
      {!isLoadingPedidos &&
        pedidos &&
        pedidos?.map((page) =>
          page?.map((pedido) => (
            <>
              {panel === Panels.detalhesPedido && (
                <>
                  {openDetails &&
                    idDetalhes === pedido?.id &&
                    !!detalhePedido?.id && (
                      <>
                        <Button
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                          onClick={hendlerChangePedidos(Panels.PedidosList)}
                        >
                          <ArrowBackIosIcon
                            sx={{ fontSize: 15 }}
                          ></ArrowBackIosIcon>
                          Voltar aos pedidos
                        </Button>
                        <DetalhePedidoCambio
                          isLoading={isLoadingDetalheTurismo || isLoadingDetalheComercial || isLoadingDetalheOuro}
                          dadosPedido={detalhePedido}
                        />
                      </>
                    )}
                </>
              )}
            </>
          ))
        )}
    </>
  );
};
