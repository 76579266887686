import { useAuth } from "../../../../hooks/AuthContext";
import { Grid, Typography } from "@mui/material";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import { ClienteEndereco } from "../../../../components";
import PendenciaCliente from "../../../../components/PendenciaCliente";
import TiposPanel from '../../../../util/typesTipoPanelsMeusDados';

const Pendencias = ({ handlerCancelEdit, setPanel }) => {
      const { user } = useAuth();


      const handlerPanelDocumentos = () => {
            setPanel(TiposPanel.editDocumentos)
      }

      const handlerPanelDadosCadastrais = () => {
            setPanel(TiposPanel.editDados)
      }

      return (
            <div style={{ padding: "15px" }}>

                  <ButtonVoltar sx={{ m: 0, pl: 0 }} handleVoltar={handlerCancelEdit} mostraTexto={true} color="primary" />
                  <Typography
                        variant="h6"
                        color="text"
                        style={{ marginLeft: "0.5rem", marginBottom: 3 }}
                  >
                        Pendências
                  </Typography>
                  <Grid container item lg={8} md={12} sm={12} xs={12}>
                        <Typography variant="body1" color="text.secondary" sx={{ ml: 1, mb: 3 }}>
                              Verifica se possui alguma pendência na sua conta.
                        </Typography>
                  </Grid>

                  <PendenciaCliente
                        handlerPanelDocumentos={handlerPanelDocumentos}
                        handlerPanelDadosCadastrais={handlerPanelDadosCadastrais}
                  />



            </div>
      );
};

export default Pendencias;
