import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Item: {
   background:'#fcfcfc',
    borderRadius:'4px',
    border: '1px dashed #ccc',
    marginLeft:30,
  },
}));

export default useStyles;
