import { useState, useEffect } from "react";
import CampoTexto from "../../../Campos/CampoTexto";
import CampoCheck from "../../../Campos/CampoCheck";
import CampoData from "../../../Campos/CampoData";
import CampoNumber from "../../../Campos/CampoNumber";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";

import Seletor from "../../../Seletor";
import Grid from "@mui/material/Grid";

import { Skeleton } from "@mui/material";
import CustomGrid from "../../../GridCadastroCustom";
import { changeDadosPessoaFisica } from "../../../../api/Cadastro";
import { useAuth } from "../../../../hooks/AuthContext";
import {
  formatMoneyToDecimal,
 
} from "../../../../util/helpers";
import useAlert from "../../../../hooks/useAlert";
import EstadosCivis from "../../../../util/estadoCivis";

const sexos = [
  {
    id: 1,
    descricao: "Masculino",
  },
  {
    id: 2,
    descricao: "Feminino",
  },
  {
    id: 3,
    descricao: "Não Informado",
  },
];



export default function DadosPessoaFisica({
  classes,
  dadosForm,
  setForm,
  setData,
  isLoading,
}) {
  // const [campos, setCampos] = useState(dadosForm.dadosPessoaFisica.campos);
  const [campos, setCampos] = useState([]);
  const [ setIdEstadoCivil] = useState(
    dadosForm.dadosPessoaFisica.idEstadoCivil
  );
  const { Alert } = useAlert();

  const { user } = useAuth();

  const startCampos = (dados) => {
    setCampos(dados);
  };

  useEffect(() => {
    startCampos(dadosForm.dadosPessoaFisica.campos);
  }, []);

  const init = async (campos, estadoCivilId, idCliente) => {
    const response = await changeDadosPessoaFisica(
      campos,
      estadoCivilId,
      idCliente
    );

    setCampos(response.data);
  };

  useEffect(() => {
    init(
      dadosForm.dadosPessoaFisica.campos,
      dadosForm.dadosPessoaFisica.idEstadoCivil,
      user.id
    );
  }, []);

  const configureNewArrayCampos = (
    array,
    propriedade,
    value,
    isNumber = false
  ) => {
    let newCampos = [];
    if (isNumber) {
      newCampos = array.map((obj) => {
        if (obj.propriedade === propriedade) {
          return {
            ...obj,
            valor: formatMoneyToDecimal(value),
          };
        }
        return obj;
      });

      return newCampos;
    }

    newCampos = array.map((c) => {
      if (c.propriedade === propriedade) return { ...c, ["valor"]: value };
      else return c;
    });

    return newCampos;
  };

  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    } else {
      return value;
    }
  };

  const handlerChangeValue = (e) => {
    const camposInternos = configureNewArrayCampos(
      dadosForm.dadosPessoaFisica.campos,
      e.target.name,
      e.target.value,
      false
    );
    const newDataCampos = configureNewArrayCampos(
      campos,
      e.target.name,
      e.target.value,
      false
    );

    setCampos(() => newDataCampos);

    setForm((previewData) => ({
      ...previewData,
      dadosPessoaFisica: {
        ...previewData.dadosPessoaFisica,
        campos: camposInternos,
      },
    }));


    setData((previewData) => ({
      ...previewData,
      dadosPessoaFisica: {
        ...previewData.dadosPessoaFisica,
        campos: camposInternos,
      },
    }));
  };

  const handlerChangeValueNumero = (value, propriedade) => {


    const updatedObjects = configureNewArrayCampos(
      dadosForm.dadosPessoaFisica.campos,
      propriedade,
      value,
      true
    );
    const newDataCampos = configureNewArrayCampos(
      campos,
      propriedade,
      value,
      true
    );

    setCampos((prevState) => newDataCampos);

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica.campos = updatedObjects;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica.campos = updatedObjects;
      return newCliente;
    });

  };



  const handleChangeCheck = (e) => {
    const camposInternos = dadosForm.dadosPessoaFisica.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica["campos"] = camposInternos;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica["campos"] = camposInternos;
      return newCliente;
    });

    init(
      dadosForm.dadosPessoaFisica.campos,
      dadosForm.dadosPessoaFisica.idEstadoCivil,
      user.id
    );
  };

  const handlerChangeSelect = (name, value) => {
    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica[name] = value;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica[name] = value;
      return newCliente;
    });

    if (name === "idEstadoCivil") {
      init(dadosForm.dadosPessoaFisica.campos, value, user.id);

      setIdEstadoCivil(value);
    }
  };

  const camposConjugue = campos.filter(
    (c) =>
      c.propriedade === "NomeConjuge" ||
      c.propriedade === "CpfCojugue" ||
      c.propriedade === "PoliticamenteExpostaConjugue"
  );

  const camposSemConjugue = campos.filter(
    (c) =>
      c.propriedade !== "NomeConjuge" &&
      c.propriedade !== "CpfCojugue" &&
      c.propriedade !== "PoliticamenteExpostaConjugue"
  );

  return (
    <>
      <Alert />
      <Grid
        container
         rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        
      >
        {dadosForm.dadosPessoaFisica.enableIndicacoes &&
          dadosForm.dadosPessoaFisica.idIndicacao > 0 &&
          (isLoading ? (
            <Grid item sx={{ pr: 0 }} xs={6} md={6}>
              <Skeleton animation="wave" height={60} width={"100%"} />
            </Grid>
          ) : (
            <Grid item xs={6} md={6}>
              <Seletor
                sx={{ width: "100%" }}
                setSelect={(e) => handlerChangeSelect("idIndicacao", e)}
                list={dadosForm.dadosPessoaFisica.indicacoes}
                size="medium"
                label="Indicações" 
                fullWidth={true}
                value={dadosForm.dadosPessoaFisica.idIndicacao}
              />
            </Grid>
          ))}

        {dadosForm.dadosPessoaFisica.enableSexo &&
          dadosForm.dadosPessoaFisica.idSexo > 0 &&
          (isLoading ? (
            <Grid item sx={{ pr: 2 }} xs={6} md={6}>
              <Skeleton animation="wave" height={60} sx={{ pr: 2 }} />
            </Grid>
          ) : (
            <Grid item xs={6} md={6}>
              <Seletor
                setSelect={(e) => handlerChangeSelect("idSexo", e)}
                list={sexos}
                size="medium"
                label="Sexo"
                fullWidth={true}
                value={dadosForm.dadosPessoaFisica.idSexo}
              />
            </Grid>
          ))}
        {dadosForm.dadosPessoaFisica.enableEstadoCivil &&
          dadosForm.dadosPessoaFisica.idEstadoCivil > 0 &&
          (isLoading ? (
            <Grid item sx={{ pl: 1 }} xs={6} md={6}>
              <Skeleton animation="wave" height={60} sx={{ pr: 2 }} />
            </Grid>
          ) : (
            <Grid item xs={6} md={6}>
              <Seletor
              
                setSelect={(e) => handlerChangeSelect("idEstadoCivil", e)}
                list={EstadosCivis}
                size="medium"
                fullWidth={true}
                label="Estado Civil"
                value={dadosForm.dadosPessoaFisica.idEstadoCivil}
              />
            </Grid>
          ))}

{camposSemConjugue &&
          camposSemConjugue.map((c, i, array) => (
            <CustomGrid key={`cg_${i}`} index={i} array={array} md={6}>
              <>
                {c.tipoControle === "Texto" &&
                  c.tipoMascara === null &&
                  (isLoading ? (
                    <Skeleton animation="wave" height={60} />
                  ) : (
                    <>
                      <CampoTexto
                        key={`ct_${i}`}
                        enable={c.enable}
                        variant="filled"
                        size="medium"
                        fullWidth={true}
                        label={c.label}
                        name={c.propriedade}
                        maximoDigitos={c.maxLength}
                        value={c.valor ?? ""}
                        handlerChange={handlerChangeValue}
                        required={c.obrigatorio}
                        className={classes.campo}
                      />
                    </>
                  ))}

                {(c.tipoControle === "Data" || c.tipoMascara === "Data") &&
                  (isLoading ? (
                    <Skeleton animation="wave" height={55} />
                  ) : (
                    <CampoData
                      key={`cd_${i}`}
                      index={i}
                      enable={c.enable}
                      size="medium"
                      label={c.label}
                      fullWidth={true}
                      name={c.propriedade}
                      value={formatDateValueInput(c.valor)}
                      onChange={handlerChangeValue}
                      className={classes.campo}
                      required={c.obrigatorio}
                    />
                  ))}
                {/* <Grid container sx={{ display: "flex" }}> */}
                {c.tipoControle === "Check" &&
                  (isLoading ? (
                    <Skeleton animation="wave" height={60} />
                  ) : (
                    <CampoCheck
                      key={`cc_${i}`}
                      index={i}
                      enable={c.enable}
                      variant="filled"
                      size="medium"
                      fullWidth={false}
                      label="Sim"
                      descricao={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      handleChangeCheck={handleChangeCheck}
                      className={classes.campo}
                    />
                  ))}

                {c.tipoMascara === "CPF" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"999.999.999-99"}
                        value={c.valor}
                        enable={c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            spellCheck="false"
                           
                            required={c.obrigatorio}
                            sx={{ mt: 0 }}
                            fullWidth={true}
                            label={c.label}
                            maxLength={c.maxLength}
                            name={c.propriedade}
                            value={c.valor}
                            size="medium"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}

                {c.tipoMascara === "CNPJ" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"99.999.999/9999-99"}
                        value={c.valor}
                        enable={c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            spellCheck="false"
                          
                            required={c.obrigatorio}
                            fullWidth={true}
                            label={c.label}
                            maxLength={c.maxLength}
                            name={c.propriedade}
                            value={c.valor}
                            size="medium"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}

                {c.tipoMascara === "Celular" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"(99) 99999-9999"}
                        value={c.valor}
                        enable={c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            spellCheck="false"
                         
                            required={c.obrigatorio}
                            sx={{ mt: 0, m: 2 }}
                            fullWidth={false}
                            label={c.label}
                            maxLength={c.maxLength}
                            name={c.propriedade}
                            value={c.valor}
                            size="medium"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}
                {c.tipoControle === "Numero" &&
                  (isLoading ? (
                    <Skeleton animation="wave" height={60} />
                  ) : (
                    <>
                      <CampoNumber
                        key={i}
                        enable={c.enable}
                        variant="outlined"
                        size="medium"
                        fullWidth
                        label={c.label}
                        name={c.propriedade}
                        value={c.valor}
                        onChange={handlerChangeValueNumero}
                        className={classes.campo}
                        maximoDigitos={c.maxLength}
                        required={c.obrigatorio}
                      />
                    </>
                  ))}
              </>
            </CustomGrid>
          ))}


      </Grid>
    
  

     
    </>
  );
}
