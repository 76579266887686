import React, { Fragment, useCallback, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Alert, AlertTitle, Slide } from "@mui/material";

const tipos = {
  error: "error",
  success: "success",
  info: "info",
  warning: "warning",
};

function Transition(props) {
  return <Slide {...props} direction="up" />;
}

const useAlert = () => {
  const [visible, setVisible] = useState(false);
  const [tipo, setTipo] = useState("");
  const [content, setText] = useState("");
  const [duration, setDuration] = useState(3000);
  const [transition, setTransition] = useState(() => Transition);

  const handlerClose = () => {
    setVisible(false);
  };

  function Transition(props) {
    return <Slide {...props} direction="top" />;
  }

  // function tituloAlert() {
  //    switch (tipo) {
  //       case tipos.error:
  //          return "Ops, algo deu errado !!"
  //       case tipos.info:
  //          return "Informação inportante !!"
  //       case tipos.warning:
  //          return "Ops, houve um alerta !!"
  //       default:
  //          return "Oba, sucesso !!"
  //    }
  // }

  function tituloAlert() {
    switch (tipo) {
      case tipos.error:
        return "Erro";
      case tipos.info:
        return "Informação";
      case tipos.warning:
        return "Alerta";
      default:
        return "Sucesso";
    }
  }

  const Snack = useCallback(
    ({ isTitulo = false, styleClass }) => {
      return (
        <Snackbar

          ClickAwayListenerProps={{ onClickAway: () => null }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={visible}
          autoHideDuration={duration}
          TransitionComponent={transition}
          onClose={handlerClose}
          className={styleClass ?? ""}
        >
          {/* <AlertTitle>{tituloAlert()}</AlertTitle> */}
          {/* {
                     isTitulo &&
                     <AlertTitle AlertTitle > {tituloAlert()}</AlertTitle>
                  } */}
          <Alert
            sx={{ whiteSpace: "pre-line", lineHeight: "18px" }}
            severity={tipo}
            onClose={handlerClose}
          >
            {content}
          </Alert>
        </Snackbar>
      );
    },
    [visible, content]
  );

  return {
    Alert: Snack,
    showAlertSuccess: (content) => {
      setVisible(true);
      setTipo(tipos.success);
      setText(content);
      setDuration(10000);
    },
    showAlertError: (content) => {
      setVisible(true);
      setTipo(tipos.error);
      setText(content);
      setDuration(10000);
    },
    showAlertInfo: (content) => {
      setVisible(true);
      setTipo(tipos.info);
      setText(content);
      setDuration(10000);
    },
    showAlertWarning: (content) => {
      setVisible(true);
      setTipo(tipos.warning);
      setText(content);
      setDuration(10000);
    },
  };
};

export default useAlert;
