import React, { useEffect } from "react";
import {
  CLIENTEDOCUMENTOS,
  TIPOCLIENTEDOCUMENTOS,
} from "../../../../util/typesReactQuery";
import { useQuery, useInfiniteQuery } from "react-query";
import { Grid, Skeleton } from "@mui/material";
import { useAuth } from "../../../../hooks/AuthContext";
import DocumentoController from "../../../../components/EdicaoCadastro/Documentos";
import {
  getDocumentosCliente,
  getTiposDocumentoCliente,
} from "../../../../api/chamadasApi";
import Button from "@mui/material/Button";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import useAlert from "../../../../hooks/useAlert";
import useStyles from "./styles";

export default function Documento({ handlerCancelEdit }) {
  const { user } = useAuth();
  const { Alert: AlertCustom, showAlertError } = useAlert();

  const {
    isLoading: isLoadingDocumento,
    refetch: refetchDocumentos,
    data: dataDocumento,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [CLIENTEDOCUMENTOS],
    ({ pageParam = 1 }) => getDocumentosCliente(user.id, pageParam),
    {
      getNextPageParam: (lastPage, allPages, data) => {
        if (lastPage.data.length === 5) {
          return allPages.length + 1;
        }
        return undefined;
      },
      onError: (error) => {
        console.error("error", error);
        showAlertError(
          `Ops, algo deu errado: ${error.message ?? error} - R102S`
        );
      },
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataTipoDocumento, isLoading: isLoadingTipoDocumento } =
    useQuery([TIPOCLIENTEDOCUMENTOS], () => getTiposDocumentoCliente(), {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (dataTipoDocumento) => { },
      onError: (error) => { },
    });

  const classes = useStyles();

  useEffect(() => {
    refetchDocumentos();
  }, []);

  return (
    <>
      <AlertCustom />
      <Grid container className={classes.Grid} >
        <Grid item xs={12} mb={0} sx={{ ml: 2, mt: 2 }}>
          <ButtonVoltar

            handleVoltar={handlerCancelEdit}
            mostraTexto={true}
          />
        </Grid>

        <DocumentoController
          documentos={dataDocumento !== undefined ? dataDocumento?.pages : null}
          tiposDocumento={dataTipoDocumento}
          loading={isLoadingDocumento || isLoadingTipoDocumento}
          userID={user.id}
        />
        <Grid item xs={12} lg={8} mb={2} sx={{ display: "flex" }}>
          {dataDocumento &&
            dataDocumento.pages.length > 0 &&
            dataDocumento.pages[0] &&
            dataDocumento.pages[0].data.length > 0 && hasNextPage &&
            (!isFetchingNextPage ? (
              <Button
                size="small"
                onClick={fetchNextPage}
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 4,
                  m: 'auto',

                }}
              >
                Carregar mais +
              </Button>
            ) : (
              <Skeleton
                width={180}
                height={60}
                animation="wave"
                sx={{ mr: 2 }}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
}
