import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  box: {
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 5,
    paddingLeft:22,
    paddingRight:30,
    width: "100%",
    },
  },
  Alert: {
    marginBottom: 15,
    alignItems:'center',
    [theme.breakpoints.up("sm")]: {
      marginLeft: 20,
      marginBottom: 20,
      alignItems:'center',
    },
  },
}));

export default useStyle;
