import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Button,
} from "@mui/material";
import { useQuery } from "react-query";
import { useStyles } from "./styles";
import { useAuth } from "../../hooks/AuthContext";
import { getLimitesDiponiveis } from "../../api/LimiteOperacional";
import theme from "../Paleta/theme";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import useAlert from "../../hooks/useAlert";
import { Link } from "react-router-dom";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { formatMoneyInString } from "../../util/helpers";
import { ButtonCustom } from "../Botoes/Button";


const mercadoOuro = "Ouro";


export default function LimiteOperacional({
  handlerShowModal,
  isLoading = false,
}) {
  const [shouldShow, setShouldShow] = useState(true);

  const { user } = useAuth();
  const classes = useStyles();
  const [isLoadingLimite, setIsloadingLimite] = useState(false);
  const { showAlertError, showAlertSuccess, Alert } = useAlert();

  const { data: limites, refetch } = useQuery(
    ["LIMITES"],
    () => getLimitesDiponiveis(user.id),
    {
      onSuccess: (data) => {
        if (!data || !data?.data) {
          showAlertError(
            "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Erro ao Buscar Limite Operacional - R453E "
          );
          return;
        }


        if (data.errorMessage) {
          showAlertError(
            `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R1800 `
          );
          return;
        }

        setIsloadingLimite(false);

      },
      onError: (error) => {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  R19FE`
        );
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    setIsloadingLimite(true);
    refetch();
  }, [])

  const ShowNotVisibleLimite = ({ limites }) => {

    return (
      <>
        {
          limites.map((row, index) => (
            <Card key={index} className={classes.Card}>
              <CardContent
                sx={{
                  pb: "0px !important",
                  p: 0,
                  background: "transparent",
                }}
              >

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 14,
                    alignItems: "center",
                    display: "flex",
                    color: "#0072ff",
                  }}
                  gutterBottom
                >
                  <SouthEastOutlinedIcon
                    fontSize="13"
                    sx={{ mr: 0.5 }}
                  />{" "}
                  Utilizado: {row?.simboloMoeda?.toUpperCase() === "BRL" ? "R$" : row?.simboloMoeda}...
                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>
                    Tipo Mercado:{" "}
                  </b>
                  <span style={{ fontWeight: 500 }}>
                    {row.mercado}
                  </span>{" "}

                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>
                    Período:{" "}
                  </b>
                  <span style={{ fontWeight: 500 }}>
                    {row.descricao}
                  </span>{" "}

                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>Limite aprovado de:</b>...
                </Typography>


                <Typography
                  variant="h5"
                  color="text.primary"
                  sx={{
                    fontWeight: "bold",

                    letterSpacing: 2,
                    fontSize: 22,
                  }}
                  component="div"
                >
                  {row?.simboloMoeda?.toUpperCase() === "BRL" ? "R$" : row?.simboloMoeda} ...
                </Typography>

                <Typography
                  sx={{ mb: 0 }}
                  variant="subtitle"
                  color="text.secondary"
                >
                  Limite Disponível
                </Typography>

              </CardContent>
            </Card>
          ))
        }
      </>
    )
  }

  const ShowLimites = ({ limites }) => {

    return (
      <>
        {
          limites.map((row, index) => (
            <Card key={index} className={classes.Card}>
              <CardContent
                sx={{
                  pb: "0px !important",
                  p: 0,
                  background: "transparent",
                }}
              >


                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 14,
                    alignItems: "center",
                    display: "flex",
                    color: "#0072ff",
                  }}
                  gutterBottom
                >
                  <SouthEastOutlinedIcon
                    fontSize="13"
                    sx={{ mr: 0.5 }}
                  />{" "}
                  Utilizado:{" "}
                  {formatMoneyInString(
                    row.limiteUtilizado,
                    row.simboloMoeda
                  )}
                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>
                    Tipo Mercado:{" "}
                  </b>
                  <span style={{ fontWeight: 500 }}>
                    {row.mercado || 'Câmbio'}
                  </span>{" "}
                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>
                    Limite aprovado de:{" "}
                  </b>
                  {formatMoneyInString(
                    row.limiteOperacional,
                    row.simboloMoeda
                  )}
                </Typography>

                <Typography
                  sx={{ fontSize: 13, mb: 2 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b>
                    Período:{" "}
                  </b>
                  <span style={{ fontWeight: 500 }}>
                    {row.descricao}
                  </span>{" "}

                </Typography>

                <Typography
                  variant="h5"
                  color="text.primary"
                  sx={{
                    fontWeight: "bold",
                    letterSpacing: 2,
                    fontSize: 22,
                  }}
                  component="div"
                >
                  {formatMoneyInString(
                    row.limiteDisponivel,
                    row.simboloMoeda
                  )}
                </Typography>

                <Typography
                  sx={{ mb: 0 }}
                  variant="subtitle"
                  color="text.secondary"
                >
                  Limite Disponível
                </Typography>
              </CardContent>
            </Card>
          ))
        }
      </>
    )
  }

  return (
    <>
      <Alert />
      <Grid
        style={{ display: "flex", width: "100%", margin: "auto" }}
        container
        className={classes.Grid}
        xs={12}
        xl={12}
        spacing={{ xs: 2, md: 0 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Box style={{ maxWidth: "100%" }}>
          <Grid
            container
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid>
                <Typography
                  sx={{ mb: 0, fontWeight: "bold" }}
                  variant="h6"
                  color="text"
                >
                  Seus limites operacionais
                </Typography>
                <Typography
                  sx={{ mb: 1 }}
                  variant="body1"
                  color="text.secondary"
                >
                  Você pode solicitar o aumento do seu limite.
                </Typography>
              </Grid>
              <Grid>
                {shouldShow === true && (
                  <>
                    <VisibilityOffOutlinedIcon
                      onClick={() => setShouldShow(!shouldShow)}
                      size={24}
                      color="black"
                      sx={{ cursor: "pointer" }}
                    />
                  </>
                )}
                {shouldShow === false && (
                  <>
                    <RemoveRedEyeOutlinedIcon
                      onClick={() => setShouldShow(!shouldShow)}
                      sx={{ cursor: "pointer" }}
                      size={24}
                      color="black"
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid>
              <Link to="/ClienteLimiteOperacionalPlus">
                <ButtonCustom
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: 12,
                    mt: 1,
                    textTransform: "capitalize",
                    borderRadius: 20,
                  }}
                >
                  + Aumentar limite
                </ButtonCustom>
              </Link>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid style={{ width: '100%' }}>

              {isLoadingLimite && (
                <>
                  {Array.from({ length: 2 }, (_, index) => (
                    <Skeleton key={index} variant="rectangular" sx={{ mt: 2 }} animation="wave" height={'15em'} width={"20rem"} />
                  ))}
                </>
              )}

              {
                !isLoadingLimite &&
                  shouldShow ? (
                  <>
                    <Box>

                      {!isLoadingLimite && (
                        <>

                          {limites &&
                            limites.data &&
                            <ShowNotVisibleLimite limites={limites.data} />
                          }
                        </>
                      )}
                    </Box >
                  </>
                ) : (
                  <>
                    <Box>
                      {!isLoadingLimite && (
                        <>
                          {limites.data &&
                            <ShowLimites limites={limites.data} />
                          }
                        </>
                      )}
                    </Box>
                  </>
                )
              }
            </Grid >
          </Grid >
        </Box >
      </Grid >
    </>
  );
}
