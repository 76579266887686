import { useState } from "react";
import DocumentoCard from "../../DocumentoCard/index";
import { useTheme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Grid, Typography, Divider, Box, Fade } from "@mui/material";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import { addDocumentoCliente } from "../../../api/chamadasApi";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { CLIENTEDOCUMENTOS } from "../../../util/typesReactQuery";
import { useMutation, useQueryClient } from "react-query";
import useStyles from "./styles";
import { ButtonCustom } from "../../Botoes/Button";
import useAlert from "../../../hooks/useAlert";

const Documento = ({ documentos, loading, tiposDocumento, userID }) => {
  const initialStateDoc = {
    idTipoDocumento: 0,
    base64: "",
    nomeArquivo: "",
    idCliente: userID,
    contentType: "",
    tamanho: 0,
  };

  const { Alert, showAlertError, showAlertSuccess } = useAlert()

  const [isLoading, setIsLoading] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [formData, setFormData] = useState(initialStateDoc);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const mutationAddDocumento = useMutation(addDocumentoCliente, {
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Oba, seu documento foi anexado com sucesso");
        setFormData(initialStateDoc);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {

      showAlertError(
        "Ops, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      queryClient.invalidateQueries(CLIENTEDOCUMENTOS);
    },
  });

  const handleChangeTipoDocumento = (event) => {
    setFormData({
      ...formData,
      idTipoDocumento: event.target.value,
    });
  };

  const handlerOpenDoc = (event) => {
    event.preventDefault();
    setOpenAddDoc(!openAddDoc);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.error("Error: ", error);
    };
  };

  const handlerChangeDoc = (event) => {
    let file = event.target.files[0];
    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const handlerEnviarDoc = (event) => {
    event.preventDefault();
    setIsLoading(true);
    mutationAddDocumento.mutate(formData);
  };

  const classes = useStyles();
  return (
    <>
      <Alert />
      <Grid item xs={12} className={classes.container}>
        <Grid
          item
          xs={10}
          container
          sx={{ pl: 2, display: "flex", justifyContent: "space-between" }}
        >
          <Typography sx={{ mt: 0 }} variant="h6">
            Adicionar novo documento
          </Typography>
        </Grid>

        <Box className={classes.Box}>
          {isLoading ?
            <>
              <Skeleton
                variant="rectangular"
                height={80}
                sx={{ mt: 2 }}
              />
              <Skeleton
                variant="rectangular"
                height={50}
                sx={{ mt: 2 }}
              />
            </>
            :
            <>
              <Button component="label" size="small" onClick={handlerOpenDoc}>
                <DriveFolderUploadIcon />
              </Button>
              <Typography>
                Faça o upload do seu documento.{" "}
                <Button component="label" size="small" onClick={handlerOpenDoc}>
                  Adicionar Documento
                </Button>
              </Typography>
              <Typography color="text.secondary" variant="subtitle1">
                Seu arquivo não deve ultrapassar 5MB
              </Typography>
              {formData.tamanho > 0 && (
                <Box
                  component="img"
                  src={formData.base64}
                  style={{
                    maxHeight: "60px",
                    maxWidth: "130px",
                    top: "50%",
                    left: "50%",
                    borderRadius: 8,
                  }}
                />
              )}

              {formData.tamanho > 0 && formData.idTipoDocumento > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Button
                    sx={{ width: 150 }}
                    variant="contained"
                    component="label"
                    size="small"
                    onClick={handlerEnviarDoc}
                  >
                    Enviar
                  </Button>
                </Box>
              )}
            </>
          }

        </Box>

        {isLoading ?

          Array.from({ length: 2 }, (_, index) => (
            <Grid container sx={{ pl: 2, mb: 4 }} key={index + "addDoc"}>
              <Grid item xs={2} md={2} lg={2} >
                <Skeleton
                  variant="rectangular"
                  height={40}

                />
              </Grid>
            </Grid>
          ))
          :
          <>
            {openAddDoc && (
              <Fade in={openAddDoc}>
                <Grid container sx={{ pl: 2, mb: 4 }}>
                  <Grid item xs={12} md={6} lg={6} mr={1} mt={1}>
                    <Typography sx={{ fontSize: ".875rem", mb: theme.spacing(2) }}>
                      Qual o tipo de documento?
                    </Typography>
                    <FormControl
                      error={formData.tamanho > 0 && formData.idTipoDocumento <= 0}
                    >
                      <Select
                        displayEmpty
                        className={classes.input}
                        value={formData.idTipoDocumento}
                        onChange={handleChangeTipoDocumento}
                      >
                        <MenuItem value={0}>Escolha uma opção</MenuItem>
                        {tiposDocumento.data.map((i) => {
                          return <MenuItem value={i.id}>{i.descricao}</MenuItem>;
                        })}
                      </Select>
                      {formData.tamanho > 0 && formData.idTipoDocumento <= 0 && (
                        <FormHelperText>
                          É necessário escolher uma opção
                        </FormHelperText>
                      )}



                      <ButtonCustom
                        sx={{ mt: 3 }}
                        variant="contained"
                        component="label"
                        size="small"
                        onChange={handlerChangeDoc}
                      >
                        <input type="file" id="file" accept="*/*" hidden />
                        Escolher arquivo <DriveFolderUploadIcon fontSize="small" sx={{ ml: 1 }} />
                      </ButtonCustom>

                    </FormControl>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    md={2}
                    mt={1}
                    lg={2}
                    mr={1}
                    sx={{ alignItems: "center", justifyContent: "flex-end" }}
                  ></Grid>
                </Grid>
              </Fade>
            )}
          </>
        }



        <Divider />

        <Grid
          container
          sx={{ pl: 2, display: "flex", justifyContent: "space-between" }}
        >
          <Typography sx={{ mb: 1, mt: 2 }} variant="h6">
            Meus documentos
          </Typography>
        </Grid>

        <Grid container sx={{ pl: 2 }}>
          {loading || isLoading ? (
            <>
              {Array.from({ length: 4 }, (_, index) => (
                <Grid item xs={12} lg={8} key={index}>
                  <Skeleton
                    variant="rectangular"
                    height={50}
                    sx={{ mt: 2 }}
                  />
                </Grid>
              ))}
            </>
          ) : (
            documentos &&
            documentos?.map((page) =>
              page?.data?.map(
                (doc, index) =>
                  doc.base64 !== "" && (
                    <Grid item xs={12} lg={8} sx={{ mt: 3 }}>
                      <Box
                        fullWidth
                        mb={2}
                        sx={{
                          width: "100%",
                          display: "flex !important",
                          justifyContent: "center !important",
                        }}
                        key={index}
                      >
                        <DocumentoCard documento={doc} key={index} />
                      </Box>
                    </Grid>
                  )
              )
            )
          )}

          {documentos === undefined && (
            <Typography
              sx={{ fontSize: ".875rem", mb: theme.spacing(2), pl: 2 }}
            >
              Ainda não há documentos anexados em seu cadastro
            </Typography>
          )}
        </Grid>

      </Grid >
    </>
  );
};

export default Documento;
