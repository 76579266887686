import api from "../index";
import { configurationVariables } from "../../environment";

const idHotsite = parseInt(configurationVariables.IdHotsite);


//?=================================== forma pagamento ================================

export const desativarConta = async (idCliente) => {
    const data = await api.delete(
        `/Cliente/DeletarCliente?idCliente=${idCliente}`
    );

    return {
        data: data?.data,
        errorMessage: data.errorMessage
    }

}


export const getPendencias = async (idCliente) => {
    const data = await api.get(
        `/cliente/Pendencias?idCliente=${idCliente}`
    );

    return {
        data: data?.data,
        errorMessage: data.errorMessage
    }

}

export const isTokenAtivo = async () => {
    const data = await api.get(
        `/cliente/IsTokenAtivo`
    );

    return {
        data: data?.data?.data ?? false,
        status: data?.data?.status ?? 0,
        errorMessage: data?.errorMessage
    }

}


export const clienteLogin = async (formData = null) => {

    let data = {}

    if (formData) {
        data = await api.post("/cliente/Login", {
            usuario: formData.email,
            senha: formData.senha,
            idHotsite: idHotsite,
            codigoAutenticacao: formData.codigoAutenticacao,
            isLoginApp: false,
        });
    }
    else {
        data.data.errorMessage = "Não recebemos nenhum dado de login -  R56EZ"
    }

    return data;

}


export const ClienteSolicitaAutenticacao = async (formData = null) => {

    let data = {}

    if (formData) {


        data = await api.post(
            "/cliente/SolicitaCodigoAutenticacaoCliente",
            {
                usuario: formData.email,
                senha: formData.senha,
                idHotsite: idHotsite,
                isLoginApp: false,
            }
        );
    }
    else {
        data.data.errorMessage = "Não recebemos nenhum dado de login - RMZCC"
    }

    return data

}

