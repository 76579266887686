import Grid from "@mui/material/Grid";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fragment, useEffect, useRef, useState } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import useStyles from "./style";

export default function CampoDocumento({
  doc,
  setData,
  isLoading,
  handlerDeleteImage,
}) {
  const [formData, setFormData] = useState(doc);
  const classes = useStyles();
  const imageInputRef = useRef(null);

  const handlerChangeDoc = (event) => {
    let file = event.target.files[0];
    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });

      setData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const handlerDeleteDoc = (documento) => () => {
    let editDoc = {
      ...documento,
      nomeArquivo: null,
      contentType: null,
      base64: null,
      tamanho: 0,
    };

    setFormData(() => ({ ...editDoc }));

    if (imageInputRef) imageInputRef.current.value = "";

    handlerDeleteImage(editDoc);
  };

  return (
    <>
      <Grid container sx={{ pl: 0, mb: 4, display: "flex" }}>
        <Grid
          item
          className={classes.Item}
          xs={12}
          md={6}
          lg={12}
          mr={1}
          onClick={handlerChangeDoc}
          mt={1}
        >
          {isLoading ? (
            <Skeleton animation="wave" width={300} height={40} sx={{ m: 2 }} />
          ) : (
            <Fragment>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ mt: 0, m: 2, textTransform:'capitalize'}}
                  variant="text"
                  component="label"
                  size="large"
                  onChange={handlerChangeDoc}
                >
                  <GetAppIcon sx={{mr:1}} fontSize="large" />
                  <input type="file" ref={imageInputRef} accept="*/*" hidden />
               Anexar
                </Button>

                <Typography
                  variant="body1"
                  color="text"
                  sx={{
                    mt: 0,

                    mb: 0,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {doc.tipoDocumento}
                  {formData.tamanho > 0 && (
                    <>
                      <DoneIcon sx={{ ml: 2, fontSize: 30 }} />
                    </>
                  )}
                </Typography>
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: 2,
                  }}
                >
                  {formData.tamanho > 0 && (
                    <>
                      <Button
                        variant="text"
                        sx={{ p: 0, minWidth: 0 }}
                        onClick={handlerDeleteDoc(formData)}
                      >
                        <DeleteIcon color="error" />
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Fragment>
          )}
        </Grid>
        <Grid
          container
          xs={12}
          md={2}
          mt={1}
          lg={2}
          mr={1}
          sx={{ alignItems: "center", justifyContent: "flex-end" }}
        ></Grid>
      </Grid>
    </>
  );
}
