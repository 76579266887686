import { useEffect, useState } from "react";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import MenuDashboard, { Paginas } from "../../../components/MenuDashboard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListItemButton from "@mui/material/ListItemButton";
import RoomIcon from "@mui/icons-material/Room";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import RightArrowIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import useStyles from "./styles";
import DadosPessoais from "./dadosPessoais";
import MeusEnderecos from "./endereco";
import Documentos from "./documentos";
import MeusDadosBancarios from "./MeusDadosBancarios";
import MinhaConta from "./MinhaConta";
import WarningIcon from '@mui/icons-material/Warning';
import { Badge } from "rsuite";
import BadgeCustom from "../../../components/Badges/BadgeCustom";
import useCheckPendencia from "../../../hooks/useCheckPendencia";
import Pendencias from './pendencias'
import useAlert from "../../../hooks/useAlert";
import TipoPanels from "../../../util/typesTipoPanelsMeusDados";


const painelsMenus = [
  {
    title: "Dados Pessoais",
    text: "Mantenha os dados pessoais atualizados.",
    icon: <PersonIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.editDados,

  },
  {
    title: "Endereço",
    text: "Mantenha o endereco atualizado, para não haver conflito em suas operações.",
    icon: <RoomIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.editEnderecos,

  },
  {
    title: "Documentos",
    text: "Confira os documentos de seu cadastro ou insira novos documentos.",
    icon: <InsertDriveFileIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.editDocumentos,
  },
  {
    title: "Dados bancários",
    text: "Confira os dados bancários de seu cadastro ou insira novos dados.",
    icon: <AccountBalanceIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.editDadosBancarios,
  },
  {
    title: "Pendências",
    text: "Verifica se possui alguma pendência na sua conta.",
    icon: <WarningIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.pendencias,
  },
  {
    title: "Minha Conta",
    text: "Configurar sua conta na loja.",
    icon: <ManageAccountsIcon
      sx={{
        color: "#333",
        background: "#eee",
        borderRadius: "20px",
        fontSize: 22,
        margin: 1,
        width: 30,
        height: 30,
        padding: "5px",
      }}
    />,
    idTipo: TipoPanels.editMinhaConta,
  },
]

export const MeusDados = () => {
  const cssStyles = useStyles();
  const [panel, setPanel] = useState(TipoPanels.OptionsEditions);
  const theme = useTheme();
  const { hasPendencia, messageError } = useCheckPendencia();
  const { Alert, showAlertError } = useAlert();

  useEffect(() => {
    if (!!messageError) showAlertError(messageError)
  }, [messageError])


  const hendlerChangePanel = (id) => (event) => {
    setPanel(id);
  };

  return (
    <>
      <Alert />
      <MenuDashboard pagina={Paginas.MeusDados}>
        <Grid
          container
          xs={12}
          spacing={{ xs: 0, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            margin: "auto !important",
            pt:4,
            alignItems: "center !important",
          }}
        >
          {panel === TipoPanels.OptionsEditions && (
            <Grid item xs={8}>
              <List>
                {
                  painelsMenus.map((item, index) => (
                    <ListItem key={index} sx={{ mb: 2 }} disablePadding>
                      <ListItemButton
                        // id={Panels.editDados.toString()}
                        onClick={hendlerChangePanel(item.idTipo)}
                      >
                        <ListItemText
                          sx={{ display: "flex" }}
                          className={cssStyles.optionsMenu}
                          primary={
                            <>
                              <p>
                                {item.icon}{" "}
                              </p>
                            </>
                          }
                          secondary={
                            <>
                              <Grid sx={{ ml: 1, mt: 1 }}>
                                <Typography sx={{ lineHeight: 0 }}>
                                  {item.title}
                                  {item.idTipo === TipoPanels.pendencias && hasPendencia &&
                                    <BadgeCustom
                                      isImportant={true}
                                      quantidade={"!"}
                                      sx={{ position: 'relative', top: '-.68rem', right: '-0.48rem' }}
                                    />
                                  }
                                </Typography>
                                <br></br>
                                <Typography
                                  variant="subtitle"
                                  sx={{ lineHeight: "15px" }}
                                  color="text.secondary"
                                >
                                  {item.text}
                                </Typography>{" "}
                              </Grid>
                            </>
                          }
                        />

                        <ListItemIcon
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <RightArrowIcon sx={{ fontSize: ".93rem" }} />
                        </ListItemIcon>

                      </ListItemButton>
                    </ListItem>
                  ))
                }

              </List>
            </Grid>
          )}
        </Grid>

        {panel === TipoPanels.editDados && (
          <Box sx={{mt:5}}>
          <DadosPessoais
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
          />
          </Box>
        )}
        {panel === TipoPanels.editEnderecos && (
          <MeusEnderecos
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
          />
        )}
        {panel === TipoPanels.editDocumentos && (
          <Documentos
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
          />
        )}
        {panel === TipoPanels.editDadosBancarios && (
          <MeusDadosBancarios
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
          />
        )}
        {panel === TipoPanels.pendencias && (
          <Pendencias
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
            setPanel={setPanel}
          />
        )}
        {panel === TipoPanels.editMinhaConta && (
          <MinhaConta
            handlerCancelEdit={hendlerChangePanel(TipoPanels.OptionsEditions)}
          />
        )}
      </MenuDashboard>
    </>
  );
};
