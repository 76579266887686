import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

export default function StepLine({ steps, orientation, stepItem, maxWidth }) {
  const style = {
    maxWidth: maxWidth || 400,
  };

  return (
    <Box sx={style}>
      <Stepper
        activeStep={stepItem.index}
        orientation={orientation ? orientation : "vertical"}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                step.isFinish ? (
                  <Typography
                    variant="caption"
                    sx={{ color: "#fff" }}
                  ></Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <>
              <Typography>{step.description}</Typography>
            </>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
