import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Modal } from "..";
import { useSeguro } from "../../store/seguroViagem";
import theme from "../Paleta/theme";
import { formatMoneyInString } from "../../util/helpers";
import { useMutation } from "react-query";
import { getCoberturas } from "../../api/Carrinho/SeguroViagem";
import useAlert from "../../hooks/useAlert";

export default function SeguroDetalhesPacote({
  isValorVisible = false,
  showModal,
  handlerClose,
  setShowModal,
}) {
  const { infoSeguro } = useSeguro();
  const { Alert, showAlertError } = useAlert();
  const [detalhes, setDetalhesSeguros] = useState();

  const mutationGetCobertura = useMutation(getCoberturas, {
    onSuccess: (response) => {
      if (response?.errorMessage && response?.errorMessage?.length > 0) {
        showAlertError(`Ahh, algo deu Errado: ${response.errorMessage}`);
      }

      setDetalhesSeguros(response.data.data);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },

  });

  useEffect(() => {

    if (!Boolean(!!infoSeguro?.pacoteSelecionado?.nomeProduto)) {
      console.error("Não foi encontrado nenhum pacote");
      return;
    }

    if (showModal)
      mutationGetCobertura.mutate(infoSeguro);
  }, [showModal]);

  return (
    <>
      <Alert />
      <Modal
        sx={{
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            width: "50%",
          },
        }}
        show={showModal}
        hasButtonClose={true}
        close={handlerClose()}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",

            pb: 3,
            pt: 2,
          }}
        >
          {infoSeguro.pacoteSelecionado?.nomeProduto}
        </Typography>
        <Grid
          container
          lg={12}
          sx={{
            mt: 3,

          }}
        >
          <Grid lg={12}>
            {detalhes?.length > 0
              ? detalhes.map((item, index) => (
                <Fragment key={index}>
                  {!!item?.detalhe?.length && (
                    <Typography sx={{ mb: 0.5, mt: 3 }}>
                      {item?.valorCobertura?.toUpperCase() === "INCLUIDO" ? (
                        <>
                          <CheckIcon
                            color="success"
                            sx={{ fontSize: 12, mr: 1 }}
                          />
                          {`${item?.detalhe} ${item?.valorCobertura}`}
                        </>
                      ) : (
                        <b>{item?.detalhe}</b>
                      )}
                    </Typography>
                  )}

                  {item?.valorCobertura?.toUpperCase() !== "INCLUIDO" &&
                    !!item?.valorCobertura?.length && (
                      <Typography
                        sx={{
                          mb: 3,
                          mt: !!item?.detalhe?.length ? 0 : 0.5,
                        }}
                      >
                        {item?.valorCobertura}
                      </Typography>
                    )}

                  {/* <Divider /> */}
                </Fragment>
              ))
              : Array.from({ length: 6 }, (_, index) => (
                <Skeleton
                  key={index}
                  height={50}
                  animation="wave"
                  width={"100%"}
                />
              ))}
          </Grid>
          {isValorVisible && (
            <Grid lg={12} md={12} sx={{ mt: 4 }}>
              <Typography sx={{ textAlign: "end" }}>Valor final</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  mb: 3,
                }}
              >
                <Typography
                  onClick={() => setShowModal(!showModal)}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    ml: 1,
                    fontSize: 31,
                  }}
                  color="text"
                >
                  {formatMoneyInString(
                    infoSeguro.pacoteSelecionado?.valorTotal
                  )}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  );
}
