import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  Container: {
    margin: 10,
    width: "100%",
    padding: '.5rem'
  },
  Card:{
    background:"#fcfcfc", 
    boxShadow:"none !important"
  }
}));
