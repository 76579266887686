import useAlert from "../../../hooks/useAlert";

import { MenuDashboard, PaginasMenuDashboard } from "../../";
import { Grid, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
export default function CadastroRealizado() {
  const { Alert, showAlertError, showAlertSuccess } = useAlert();

  return (
    <>
      <Alert />
      <MenuDashboard pagina={PaginasMenuDashboard.PaginaInicial}>
        <Grid
          md={12}
          sm={12}
          lg={12}
          container
          //className={classes.Container}
          justifyContent="center"
          alignItems="baseline"
        >
          <Grid item md={6} sm={12}  sx={{ marginTop: "12%" }}>
            
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: 1, mt: 1,  textAlign: "center" }}
            >
           Cadastro efetuado com sucesso
            </Typography>
            <Typography
              variant="h5"
              sx={{ mb: 1, mt: 1, fontWeight: "bold", textAlign: "center" }}
            >
             Seja bem vindo(a) à nossa plataforma
            </Typography>

            <Box sx={{margin:'auto', display:'flex', justifyContent:"center"}}>
              {" "}
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: "80px !important",
                  justifyContent: "center",
                  display: "flex",
                  mt:3
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </MenuDashboard>
    </>
  );
}
