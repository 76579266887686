import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { SaqueSaldo } from "../../api/chamadasApi";
import { StepCustom, Position } from "../Steps/StepCustom";
import Divider from "../DeviderCustom/index";
import { useAuth } from "../../hooks/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import style from "./styleSaque";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import AlertMessage from "@mui/material/Alert";
// import { getDadosBancariosCliente } from "../../api/chamadasApi";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import { NOTIFICATION } from "../../util/typesReactQuery";
import { ButtonCustom } from "../Botoes/Button";
import { formatMoneyInString } from "../../util/helpers";
import CampoOperacao from "../Campos/CampoOperacao";

const steps = [
  {
    label: "",
    show: false,
  },

  {
    label: "",
    show: false,
  },
  {
    label: "",
    show: false,
  },
];

const SaqueReais = ({
  queryKey,
  showAlertError,
  showAlertSuccess,
  handlerShowAlert,
  tipoOperacaoSaldo,
  modalControl,
  valueSaldo,
}) => {
  const { user } = useAuth();
  const cssStyle = style();
  const [bancoNacionais, setBancoNacionais] = useState(null);
  const [selectedBanco, setSelectedPix] = useState(null);
  const [collapseOpen, setCollapseOpen] = useState(null);
  const [formData, setFormData] = useState({
    valorReais: "0,00",
    idTipoOperacaoSaldo: tipoOperacaoSaldo?.id,
    observacao: "Saque de Saldo",
    idBancoNacionalCanalBancario: 0,
    idCliente: user?.id,
    idDadoBancarioCliente: 0,
  });

  const [activeSteps, setActiveSteps] = useState(0);
  const [permiteNextStep, setPermiteNextEstep] = useState(false);

  //?Query para realizar o post de saque e atualizar o saldo
  const queryClient = useQueryClient();
  const mutation = useMutation(SaqueSaldo, {
    //função chamada quando concluido a chamada da api
    onMutate: async (responseAddSaldo) => {
      showAlertSuccess("Recebemos a sua solicitação de saque");
      if (modalControl) modalControl(false);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
          err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(NOTIFICATION);
    },
  });

  useEffect(() => {
    let valoreReal = 0;
    if (formData.valorReais) {
      valoreReal = parseFloat(
        formData.valorReais
          .replaceAll(".", "")
          .replace(" ", "")
          .replace("R$", "")
          .replace(",", ".")
      );
      if (valoreReal > valueSaldo)
        setFormData({ ...formData, valorReais: valueSaldo.toString() });
    }

    switch (activeSteps) {
      case 0:
        if (valoreReal <= 0) setPermiteNextEstep(false);
        else setPermiteNextEstep(true);
        break;
      case 1:
        if (formData.idDadoBancarioCliente <= 0) setPermiteNextEstep(false);
        else setPermiteNextEstep(true);
        break;
      default:
        setPermiteNextEstep(true);
        break;
    }
  }, [formData.valorReais, formData.idDadoBancarioCliente, activeSteps]);

  // useEffect(() => {
  //   (async () => {
  //     let apenasPix = true;
  //     try {
  //       const response = await getDadosBancariosCliente(user.id, apenasPix);
  //       setBancoNacionais(response.data);
  //     } catch (error) {
  //       console.error("❌ ", error + " RW8IG");
  //       handlerShowAlert(true);
  //       showAlertError(
  //         "Opa, não foi possivel registrar sua solicitação de saque"
  //       );
  //     }
  //   })();
  // }, []);

  const handlerChangeValue = (event) => {
    setFormData({ ...formData, valorReais: event.target.value });
  };

  const handlerBlurValue = (event) => {
    if (!formData.valorReais.includes(","))
      setFormData({ ...formData, valorReais: formData.valorReais + ",00" });
  };

  const handlerSetBanco = (BancoComponent, idBanco) => (event) => {
    event.preventDefault();
    setSelectedPix(BancoComponent);
    setFormData({ ...formData, idDadoBancarioCliente: idBanco });
  };

  const handlerSelectTotalSaldo = (event) => {
    event.preventDefault();
    setFormData({ ...formData, valorReais: "R$ " + valueSaldo.toString() });
  };

  function ValorSaque() {
    return (
      <Grid contained>
        {valueSaldo <= 0 && (
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <AlertMessage severity="error">
              Seu saldo é insuficiente para realizar saques
            </AlertMessage>
          </Grid>
        )}
        <Divider />
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <Typography id="modal-modal-title" variant="h6">
            Quanto você precisa sacar?
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <CampoOperacao
            fullwidth={true}
            value={formData.valorReais}
            onChange={handlerChangeValue}
            onblur={handlerBlurValue}
            casasDecimais={2}
            size="medium"
            prefix={"R$ "}
          />
        </Grid>
        <Grid item xs={12} className={cssStyle.container_valorSaldoSaque}>
          <Typography variant="caption">
            Seu Saldo Total: <b>{formatMoneyInString(valueSaldo)}</b>
          </Typography>
          <ButtonCustom
            variant="text"
            size={"small"}
            onClick={handlerSelectTotalSaldo}
          >
            Sacar Saldo Total
          </ButtonCustom>
        </Grid>
      </Grid>
    );
  }

  const handlerOpenCollapseDetalhes = (idCollapse) => (event) => {
    event.preventDefault();
    if (idCollapse === collapseOpen) setCollapseOpen(null);
    else setCollapseOpen(idCollapse);
  };

  const formatMoney = (value) => {
    return parseFloat(
      value
        .replace(".", "")
        .replace(" ", "")
        .replace("R$", "")
        .replace(",", ".")
    );
  };

  const handlerSubmitAddSaldo = async (event) => {
    event.preventDefault();
    let valorFormatado = formatMoney(formData.valorReais);
    mutation.mutate({ ...formData, valorReais: valorFormatado });
  };

  function PagamentoPix() {
    return (
      <>
        {" "}
        <Typography id="modal-modal-title" variant="h6">
          Em qual banco deseja receber?
        </Typography>
        <br></br>
        {!!bancoNacionais &&
          bancoNacionais.map((banco, index) => (
            <>
              <Card
                style={{
                  marginTop: 10,
                  background: "#fdfdfc",
                  boxShadow: "none",
                  border: "1px solid #bbb",
                }}
                key={banco.id}
                onClick={handlerOpenCollapseDetalhes(`collapse${index}`)}
                className={
                  selectedBanco === `banco${index}` ? cssStyle.checked : {}
                }
              >
                <CardActionArea
                  onClick={handlerSetBanco(`banco${index}`, banco?.id)}
                >
                  <CardContent>
                    <Typography
                      style={{
                        fontWeight: 500,
                        color: "black",
                        textAlign: "left",
                      }}
                      id="modal-modal-title"
                      variant="body2"
                      component="h2"
                    >
                      {banco.bancoNacional.descricao}
                    </Typography>

                    <Collapse
                      in={collapseOpen === `collapse${index}`}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Typography color="text.secondary">
                        <br />
                        Chave pix: {banco.chavePIX}
                        <br />
                      </Typography>
                    </Collapse>
                  </CardContent>
                </CardActionArea>
              </Card>
            </>
          ))}
        <Accordion style={{ boxShadow: "none" }}>
          <AccordionSummary
            style={{ boxShadow: "none" }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500 }}
              color="primary"
            >
              Receber em outra conta +
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              spellCheck="false"
              sx={{ mr: 2 }}
              size="small"
              required
              id="outlined-required"
              label="Tipo de chave"
            />
            <TextField
              spellCheck="false"
              size="small"
              required
              id="outlined-required"
              label="Chave pix"
            />
            <Button
              width="100%"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              Ok
            </Button>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }

  function SubmitCredito() {
    return (
      <>
        <MoreHorizIcon
          style={{ margin: "auto", width: "100%", fontSize: 50 }}
        />
        <Typography
          sx={{ mb: 2 }}
          style={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Agradecemos a confiança
        </Typography>
        <Typography
          style={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="body2"
          component="h2"
        >
          Iremos verificar sua conta e notificaremos <br></br>quando o saque for
          concluído.
        </Typography>
      </>
    );
  }

  return (
    <Box sx={{ maxWidth: "auto", flexGrow: 1 }}>
      <StepCustom
        steps={steps}
        activeStep={activeSteps}
        setActiveStep={setActiveSteps}
        positionChildren={Position.Top}
        handlerFinsish={handlerSubmitAddSaldo}
        isNext={permiteNextStep}
      >
        <ValorSaque />
        <PagamentoPix />
        <SubmitCredito />
      </StepCustom>
    </Box>
  );
};

export default SaqueReais;
