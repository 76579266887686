import { Box, Grid, Skeleton } from "@mui/material"



const LoadingFormaPagamento = () => {
   return (
      <Grid container>
         {
            Array.from({ length: 4 }, (_, index) => {
               <Grid item xs={12} key={index}>
                  <Skeleton height={80} animation="wave" />
               </Grid>
            })
         }
      </Grid>
   )
}

export default LoadingFormaPagamento;
