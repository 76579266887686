import api from "../../index";
import { configurationVariables } from "../../../environment/index";

const idOrigem = 3;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const addItemOuro = async (
    idCliente,
    idPraca,
    idTipoPedido,
    itemCarrinho
) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/ConfiguraItemCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idPraca=${idPraca}&idTipoPedido=${idTipoPedido}`,
            itemCarrinho
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
        };
    } catch (error) {
        throw new Error(error + "R6MLI");
    }
};

export const getCarrinho = async (idCliente) => {
    try {
        const { data: response, status } = await api.get(
            `/CarrinhoOuro/GetModuloCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
        };
    } catch (error) {
        throw new Error(error + "RB09H");
    }
};

export const getEntregaOuro = async (idCliente) => {
    try {
        const { data: response, status } = await api.get(
            `/CarrinhoOuro/GetEntregaModulo?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
        };
    } catch (error) {
        throw new Error(error + "RXI8O");
    }
};


export const addEntrega = async (idCliente, entrega) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/ConfiguraEntrega?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
            entrega
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
        };
    } catch (error) {
        throw new Error(error + "RLHYA");
    }
};

export const addPagamento = async (idCliente, formaPagamento) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/ConfiguraPagamento?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
            formaPagamento
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
            nextPage: response.nextPage,
            previewPage: response.previewPage,
        };
    } catch (error) {
        throw new Error(error + "RYCKP");
    }
};

export const RemoveItemByOuroIdMoeda = async ({ idCliente, idOrigem, idMoeda }) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/RemoverItemByIDMoeda?idCliente=${idCliente}&idOrigem=${idOrigem}&idMoeda=${idMoeda}&idHotsite=${idHotsite}`
        );

        return {
            status,
            errorMessage: response.errorMessage,
        };
    } catch (error) {
        throw new Error(error + "RNT5B");
    }
};

export const finalizar = async (idCliente) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/Finalizar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idHotsite=${idHotsite}`
        );

        return {
            status,
            errorMessage: response.errorMessage,
            data: response.data,
        };
    } catch (error) {
        throw new Error(error + "RAJ0O");
    }
};


export const LimparCarrinho = async ({ idCliente }) => {
    try {
        const { data: response, status } = await api.post(
            `/CarrinhoOuro/Limpar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
        );

        return {
            status,
            errorMessage: response.errorMessage,
        };
    } catch (error) {
        throw new Error(error + "R773C");
    }
};