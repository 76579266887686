import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  ContainerSearch: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 20px",
    width: "100%",
  },
  FormControl: {
    width: "100%",
    border: "none !important",
  },
  Seletor: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: 10,
      border: "none !important",
    },
  },
  ButtonBuscar: {
    width: "100%",
    marginLeft: 0,
    marginTop: "20px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "auto",
      height: "80px",
      width: "100%",
      marginLeft: 20,
      marginBottom:0,
    },
  },
  Vitrine: {
    display: "flex",
    justifyContent: "center",
    background: "#fdfdfd",
    padding: 25,
    paddingTop: 15,
    margin: 8,

    borderRadius: 15,
    border: "1px solid #ccc",
  },
  Vitrine2: {
    display: "block",

    background: "#fafafa",

    padding: "20px",
    borderRadius: 15,
  },
  Container: {
    display: "block",
    padding: 15,
  },
  passageiros: {
    width: "100%",
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: "2.5%",
      height: "80px",
      width: "100%",
      marginLeft: 10,
    },
  },
  Title: {
    marginBottom: '10px !important',
    fontWeight: "bold !important",
    
    padding: "0px !important",

    [theme.breakpoints.up("sm")]: {
      mb: 1,
    fontWeight: "bold !important",
    mt: 0,
    padding: "22px !important",
    },
  },
}));
