import { useState, useEffect } from "react";
import { VitrineBase } from "../VitrineBase";
import {
  getVitrineEnvio,
  getValoresItemCarrinho,
} from "../../../../api/Vitrine";
import { addItemEnvio, getNaturezaByIDTipoOperacaoTipoCliente } from "../../../../api/Carrinho/TranferenciaInternacional";
import { useAuth } from "../../../../hooks/AuthContext";
import useAlert from "../../../../hooks/useAlert/index";
import { useNavigate } from "react-router-dom";
import { ButtonAcaoGrande, LoadingVitrine, Modal } from "../../../";
import {
  formatMoney,
  formatMoneyStringInDecimal,
  configInitialvalue,
} from "../../../../util/helpers";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import usePedido from "../../../../hooks/usePedido";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { Info as InfoIcon } from "@mui/icons-material";
import {
  GETVALORESITEMSREMESSA,
  VITRINEREMESSA,
  FINALIDADES
} from "../../../../util/typesReactQuery";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useQuery } from "react-query";
import ButtonAcaoGrandeOutlined from "../../../Botoes/ButtonAcaoGrande copy";
import TiposPedido from "../../../../util/typesTipoPedido";
import TiposOperacao from "../../../../util/typesTipoOperacao";

export default function Envio() {
  const { Alert, showAlertError, showAlertInfo } = useAlert();
  const { user } = useAuth();
  const [isVitrineError, setIsVitrineError] = useState(false);
  const [vitrineEnvio, setVitrineEnvio] = useState(null);
  const [itemVitrine, setItemVitrine] = useState({ valorMe: "" });
  const [termoAceito, setTermoAceito] = useState(false);
  const [termoResponsabilidade, setTermoResponsabilidade] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isErrorTermo, setIsErrorTermo] = useState(false);
  const [isErrorTermoResponsabilidade, setIsErrorTermoResponsabilidade] = useState(false);
  const [valorItemCarrinho, setValorItemCarrinho] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const { itensPedido: listaDeItensNoCarrinho } = usePedido();
  const theme = useTheme();
  const [itemCarrinho, setItemCarrinho] = useState({
    idItemVitrine: 0,
    valorMe: 0,
    valorMn: 0,
    impostos: [],
    valorVet: 0,
    idFinalidade: 0,
    idVitrine: 0,
    idHotsite: user.idHotsite,
    valorTaxa: 0,
    idTaxa: 0,
    tarifaAdministrativa: 0,
    moeda: {},
    indisponivel: false,
    indisponivelText: "",
  });

  const navigation = useNavigate();

  const { errors: errosLojaFechada, lojaFechada } = useLojaFechada();

  useEffect(() => {
    if (errosLojaFechada) {
      console.error("Primecase Error -  ", errosLojaFechada);
      showAlertError(errosLojaFechada);
      return;
    }
  }, [errosLojaFechada]);

  const init = async (response) => {
    setLoadingButton(true);
    try {
      setVitrineEnvio(response);
      setItemVitrine({
        ...response.itensVitrine[0],
        valorMe: configInitialvalue(response.itensVitrine[0]),
      });
    } catch (error) {
      showAlertError(error.message);

      setIsVitrineError(true);
    } finally {
      setLoadingButton(false);
    }
  };

  const { isFetching: isLoadingVitrine } = useQuery(
    [VITRINEREMESSA],
    () => getVitrineEnvio(user.id),
    {
      onSuccess: (data) => {
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não Foi encontrado nenhuma vitrine de Envio - RQG4K`
          );
          return;
        }
        if (data?.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - RNVYL`
          );
          return;
        }

        init(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RHI8P`
        );

        setIsVitrineError(true);
      },
      onSettled: () => {
        setLoadingButton(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: refetchImpostos, isFetching: isLoadingImpostos } = useQuery(
    [GETVALORESITEMSREMESSA],
    () =>
      getValoresItemCarrinho(
        vitrineEnvio?.id ?? 0,
        itemVitrine?.id ?? 0,
        vitrineEnvio?.idfinalidade ?? 0,
        user?.idHotsite ?? 0
      ),
    {
      onSuccess: (data) => {
        if (!!data?.errorMessage) {
          console.error(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - RM0XC`
          );
          return;
        }

        setValorItemCarrinho((prev) => ({ ...data?.data }));
        setLoadingButton(false);
      },
      onError: (error) => {
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RXF6R`
        );

        setIsVitrineError(true);
      },
      onSettled: () => {
        setLoadingButton(false);
      },
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60,
    }
  );


  useQuery(
    [FINALIDADES],
    () =>
      getNaturezaByIDTipoOperacaoTipoCliente(TiposOperacao.EnvioDinheiro, user?.idTipoCliente,),
    {
      onSuccess: (data) => {

        if (!data)
          throw new Error("Não encontramos nenhuma informação referente a natureza!")
        if (!data?.data?.length <= 0)
          throw new Error("Não encontramos nenhum natureza!")
        if (!!data?.errorMessage)
          throw new Error(data?.errorMessage)

        setVitrineEnvio((prevState) => ({ ...prevState, finalidades: data.data }))

      },
      onError: (error) => {
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RXHFU`
        );
      },

    }
  );


  useEffect(() => {
    if (itemVitrine) {
      let item = {
        idItemVitrine: itemVitrine?.id,
        valorMe: formatMoneyStringInDecimal(
          itemVitrine?.valorMe ?? itemVitrine.valorInicial
        ),
        valorMn: 0,
        impostos: [],
        valorVet: 0,
        idFinalidade: vitrineEnvio?.idfinalidade,
        idVitrine: vitrineEnvio?.id,
        idHotsite: user?.idHotsite ?? 0,
        tarifaAdministrativa: 0,
        moeda: itemVitrine?.moeda,
        indisponivel: itemVitrine?.indisponivel,
        indisponivelText: itemVitrine?.indisponivelText,
        idTipoItemCarrinho: 7,
      };
      handlerCalculate(item);
    }
  }, [itemVitrine, vitrineEnvio, valorItemCarrinho]);

  useEffect(() => {
    setItemVitrine((prev) => ({
      ...prev,
      valorMe: configInitialvalue(itemVitrine),
    }));
  }, [itemVitrine.valorInicial]);

  useEffect(() => {
    refetchImpostos();
    setItemVitrine((prev) => ({
      ...prev,
      valorMe: configInitialvalue(itemVitrine),
    }));
  }, [vitrineEnvio?.idfinalidade, itemVitrine.id]);

  const isValidacoesPreCalculo = (valor) => {
    if (formatMoneyStringInDecimal(valor) == 0) {
      setDisabledButton(true);
      if (valor != "")
        showAlertInfo(`Só é possível comprar itens com valor acima de 0`);
      return false;
    }

    if (String(valor)?.slice(-2).includes(",")) {
      setDisabledButton(true);
      return false;
    }

    if (
      valorItemCarrinho?.escala > 0 &&
      formatMoneyStringInDecimal(valor ?? 0) % valorItemCarrinho?.escala > 0
    ) {
      setDisabledButton(true);
      showAlertInfo(
        `Só é possível comprar itens em escala de ${valorItemCarrinho?.escala}`
      );
      return true;
    }

    if (
      formatMoneyStringInDecimal(valor ?? 0) < valorItemCarrinho?.valorMinimo
    ) {
      setDisabledButton(true);
      if (!!valor) {
        showAlertInfo(
          `Não é possível solicitar a quandidade de ${valor}, poís o mínimo permitido é ${valorItemCarrinho?.valorMinimo}`
        );
      }
      return true;
    }
    setDisabledButton(false);
    return true;
  };

  const handlerCalculate = async (i) => {
    try {
      if (!valorItemCarrinho) {
        setLoadingButton(true);
        refetchImpostos();
        return;
      }

      let item = i;

      if (!isValidacoesPreCalculo(item?.valorMe)) {
        return;
      }

      item.indisponivel = valorItemCarrinho?.indisponivel;
      item.indisponivelText = valorItemCarrinho?.indisponivelText;

      item.impostos = valorItemCarrinho?.impostos;
      item.idTaxa = valorItemCarrinho?.idTaxa;
      item.valorTaxaAplicada = formatMoney(
        valorItemCarrinho?.valorTaxa,
        item?.moeda?.numeroCasaDecimais
      );
      item.tarifaAdministrativa = formatMoney(
        valorItemCarrinho?.tarifaAdministrativa,
        2
      );

      const valorBaseMn = formatMoney(item.valorMe * item.valorTaxaAplicada, 2);

      let valorMnComImposto = parseFloat(valorBaseMn);

      item?.impostos?.map((i) => {
        const valorImpostoBase = formatMoney(
          (valorBaseMn * i.aliquota) / 100,
          2
        );
        i.valor = formatMoney(valorImpostoBase, 2);
        valorMnComImposto += parseFloat(valorImpostoBase);
      });
      const valorMn =
        parseFloat(valorMnComImposto) + parseFloat(item.tarifaAdministrativa);

      item.valorMn = formatMoney(valorBaseMn, 2);
      item.valorVet = formatMoney(
        valorMn / item.valorMe,
        item?.moeda?.numeroCasaDecimais
      );

      item.idCliente = user.id;
      item.valorTotalMN = valorMn;
      item.idTipoItemCarrinho = 7;
      setItemCarrinho(item);

      if (item.indisponivel) throw new Error(item.indisponivelText);

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      showAlertError(error.message);
    }
  };

  const handlerAddCart = async (event) => {
    event.preventDefault();
    setLoadingButton(true);

    try {
      if (!termoAceito) {
        setIsErrorTermo(true);
        throw new Error("É necessário aceitar o termo de uso.");
      }

      if (!termoResponsabilidade) {
        setIsErrorTermoResponsabilidade(true);
        throw new Error("É necessário aceitar o termo de responsabilidade.");
      }

      if (itemCarrinho.indisponivel)
        throw new Error(itemCarrinho.indisponivelText);

      if (lojaFechada && lojaFechada.isFechada) {
        navigation("/LojaFechada");
      } else {
        const response = await addItemEnvio(
          itemCarrinho.idCliente,
          itemCarrinho,
          vitrineEnvio.finalidades.find(
            (f) => f.id === itemCarrinho.idFinalidade
          )
        );

        if (response && !!response.errorMessage)
          throw new Error(response.errorMessage);

        if (response) {
          setTimeout(() => {
            navigation(
              "/carrinho/transferencia-internacional/envio/beneficiario"
            );
          }, 2000);
        }
      }
    } catch (error) {
      showAlertError(error.message);
      setLoadingButton(false);
    } finally {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2000);
    }
  };

  const handlerChangeFinalidade = (id) => {
    setLoadingButton(true);
    setItemCarrinho({ ...itemCarrinho, idfinalidade: id });
    setVitrineEnvio({ ...vitrineEnvio, idfinalidade: id });
  };

  const handlerChangeItem = (event) => {
    setItemVitrine((prev) => ({
      ...vitrineEnvio.itensVitrine.find((i) => i.id === event.target.value),
    }));
    setValorItemCarrinho((prev) => null);
  };

  const handlerChangeValueMe = (event) => {
    let valor = event.target.value;

    setItemVitrine((prev) => ({ ...prev, valorMe: valor }));
  };

  const handlerBlurItemChange = (event) => {
    let valor = event.target.value;

    if (!!!valor) valor = "00,00";

    if (!valor.includes(",")) valor += ",00";

    setItemVitrine((prev) => ({ ...prev, valorMe: valor }));
  };



  return (
    <>
      <Alert />


      {(loadingButton || isLoadingVitrine) && <LoadingVitrine />}
      {!loadingButton &&
        !isLoadingImpostos &&
        !isLoadingVitrine &&
        !isVitrineError &&
        vitrineEnvio && (
          <VitrineBase
            vitrine={vitrineEnvio}
            onChangeItem={handlerChangeItem}
            onAddCart={handlerAddCart}
            itemSelected={itemVitrine}
            itemCarrinho={itemCarrinho}
            onChangeFinalidade={handlerChangeFinalidade}
            onChangeValorMe={handlerChangeValueMe}
            onChangeTermo={setTermoAceito}
            isTermoResponsabilidadeAceite={termoResponsabilidade}
            isTermoUsoAceite={termoAceito}
            onChangeTermoResponsabilidade={setTermoResponsabilidade}
            onBlurValueChangeItem={handlerBlurItemChange}
            isErrorTermo={isErrorTermo}
            isErrorTermoResponsabilidade={isErrorTermoResponsabilidade}
            loadingAddcart={loadingButton}
            disabledButton={disabledButton}
          />
        )}
      {isVitrineError && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh", marginTop: "-1rem", background: "#fcfcfc" }}
        >
          <Grid item>
            <Card>
              <CardHeader subheader="❌ Comunicado importante" />
              <CardContent>
                <Typography variant="body1">
                  A vitrine não esta disponivel para operações no momento.
                  <br />
                  Para mais detalhes, entre em contato conosco.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
