import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { GetFormaPagamento } from "../../api/FormaPagamento/index";
import useAlert from "../../hooks/useAlert";
import List from "@mui/material/List";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { LoadingFormaPagamento } from "../";
import PixIcon from "@mui/icons-material/Pix";
import DadoBancario from "../../components/FormaPagamento/DadoBancario";
import TiposLiquidacao from "../../util/typesTipoLiquidacao";
import TiposPedido from "../../util/typesTipoPedido";
import DadosBancarios from "../../components/DadosBancarios";
import { Avatar, Box, Grid, ListItemIcon } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";

export default function OpcoesPagamento({
  idTipoPedido,
  onNext,
  loadingAddPagamento = false,
  idPraca,
}) {
  const [itens, setItens] = useState(null);
  const { Alert, showAlertError } = useAlert();
  const [formaTedDoc, setformaTedDoc] = useState([]);

  useQuery(
    `FORMAPAGEMENTOOPERACAO${idTipoPedido}`,
    () => GetFormaPagamento(idTipoPedido, idPraca),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!data || !data?.data) {
          showAlertError(
            "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhuma forma de pagamento encontrada - RXKP1 "
          );
          return;
        }

        if (data.errorMessage) {
          showAlertError(
            `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - RG7ZB `
          );
          return;
        }

        if (data.status) setItens(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  RI1K8`
        );
      },
    }
  );

  useEffect(() => {
    if (itens?.length > 0)
      setformaTedDoc(
        itens.filter(
          (i) =>
            i.tipoLiquidacao.id === TiposLiquidacao.Ted ||
            i.tipoLiquidacao.id === TiposLiquidacao.Doc
        )
      );
  }, [itens]);

  return (
    <>
      <Alert />
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          maxHeight: "50vh",
          overflowY: "auto",
          overflowX: "hidden",
          bgcolor: "background.paper",
        }}
      >

        {!loadingAddPagamento &&
          (idTipoPedido === TiposPedido.Recebimento ||
            idTipoPedido === TiposPedido.CompraEspecie) && (
            <DadosBancarios isOperacao={true} onNext={onNext} />
          )}
      </Box>

      <List
        dense
        sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
      >
        <List
          dense
          sx={{
            width: "100%",
            maxWidth: "100%",
            overflow: "auto",
            bgcolor: "background.paper",
          }}
        >
          {!loadingAddPagamento && itens ? (
            <>
              {itens.map((b) => (
                <>
                  {idTipoPedido === TiposPedido.Recebimento ||
                    idTipoPedido === TiposPedido.CompraEspecie ? (
                    b.tipoLiquidacao.id !== TiposLiquidacao.Ted &&
                    b.tipoLiquidacao.id !== TiposLiquidacao.Doc && (
                      <>
                        <ListItem sx={{ padding: 0 }} key={b.id}>
                          <ListItemButton
                            sx={{
                              padding: 2,
                            }}
                            onClick={(event) => onNext(event, b)}
                          >
                            <ListItemIcon>
                              {b.tipoLiquidacao.id ==
                                TiposLiquidacao.Especie ? (
                                <PaymentsIcon />
                              ) : (
                                <PixIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              multiline
                              id={b.id}
                              primary={b.descricao}
                              style={{ fontWeight: "bold" }}
                            />

                            <ArrowForwardIosIcon
                              sx={{
                                color: "#444",
                                fontSize: 14,
                                marginLeft: 2,
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </>
                    )
                  ) : (
                    <>
                      <ListItem sx={{ padding: 0, mb: 3 }} key={b.id}>
                        <ListItemButton
                          sx={{
                            padding: 2,
                          }}
                          onClick={(event) => onNext(event, b)}
                        >
                          <ListItemIcon>
                            {b.tipoLiquidacao.id === TiposLiquidacao.Doc ? (
                              <AccountBalanceIcon />
                            ) : (
                              ""
                            )}
                            {b.tipoLiquidacao.id === TiposLiquidacao.Especie ? (
                              <PaymentsIcon />
                            ) : (
                              ""
                            )}
                            {b.tipoLiquidacao.id === TiposLiquidacao.Pix ? (
                              <PixIcon />
                            ) : (
                              ""
                            )}
                            {b.tipoLiquidacao.id === TiposLiquidacao.Ted ? (
                              <AccountBalanceIcon />
                            ) : (
                              ""
                            )}
                          </ListItemIcon>

                          <ListItemText
                            multiline
                            id={b.id}
                            primary={b.descricao}
                            style={{ fontWeight: "bold" }}
                          />

                          <ArrowForwardIosIcon
                            sx={{ color: "#444", fontSize: 14, marginLeft: 2 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <LoadingFormaPagamento />
          )}
        </List>
      </List>
    </>
  );
}
