import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import brlFormatter from "../../../util/CalculosVitrine";
import Skeleton from "@mui/material/Skeleton";

export default function CampoNumber({
  label,
  value,
  size,
  key = null,
  enable = false,
  sx,
  name,
  fullWidth = true,
  onChange,
  isLoading,
  currencyFormat = "BRL",
  maximoDigitos = 999,
  variant = "outlined",
  required = false,
  isMonetario = true,
}) {
  return (
    <>
      {isLoading && (
        <Skeleton sx={{}} variant="rectangular" height={40} animation={false} />
      )}
      {!isLoading && (
        <FormControl fullWidth>
          <NumberFormat
            key={key}
            value={value}
            name={name}
            disabled={!enable}
            format={(valor) =>
              isMonetario ? brlFormatter(valor, currencyFormat) : valor
            }
            displayType="text"
            sx={sx}
            inputProps={{ maxLength: maximoDigitos > 0 ? maximoDigitos : 15 }}
            onValueChange={({ formattedValue }) =>
              onChange(formattedValue, name)
            }
            renderText={(valor, props) => (
              <>
                <TextField
                  spellCheck="false"
                  {...props}
                  size={size}
                  type="text"
                  variant={variant}
                  required={required}
                  value={valor}
                  label={label}
                  onChange={(e) => onChange(e.target.value, name)}
                />
              </>
            )}
          ></NumberFormat>
        </FormControl>
      )}
    </>
  );
}
