import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Hidden from "@mui/material/Hidden";
import InputMask from "react-input-mask";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import theme from "../Paleta/theme";
import useStyles from "./styles";
import CampoMask from "../Campos/CampoMask";
import { ButtonCustom } from "../Botoes/Button";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";

export default function CodigoAutenticacao({
  loading,
  handleSolicitaCodigoNovamente,
  handleChange,
  handleSubmit,
}) {
  const CssTextField = styled(TextField)({
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-input": {
      fontSize: 16,
      letterSpacing: 4,
    },
  });
  const classes = useStyles();
  const [counter, setCounter] = useState(60);

  const handlerSolicitaCodigoNovamente = () => {
    if (counter !== 0) {
      throw new Error(
        "A contagem para enviar novamente outro código de autenticação ainda não foi concluída. R194F"
      );
    } else {
      handleSolicitaCodigoNovamente();
      setCounter(60);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);

    if (counter === 0) clearTimeout(timer);
  }, [counter]);

  return (
    <>
      <Grid item className={classes.criar} xs={12}>
        <Grid item className={classes.criar} lg={6}>
          <Grid
            className={classes.Item}
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          ></Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <Typography className={classes.Titulo} variant="h5">
              Confirmação de e-mail
            </Typography>
            <Typography className={classes.Subtitulo} variant="body1">
              Enviamos um código de autenticação em seu e-mail, adicione ele abaixo. <br></br>
              Seu código expira em 3 minutos.
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <InputMask
                mask="9-9-9-9-9"
                maskChar={false}
                placeholder="_ _ _ _ _"
                type="number"
              >
                {(inputProps) => (
                  <FormControl
                    size="medium"
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    <CssTextField
                      margin="normal"
                      fullWidth
                      sx={{ fontSize: 40 }}
                      keyboardType="numeric" // Isso força o teclado numérico em dispositivos móveis
                      inputProps={{
                        inputMode: "numeric", // Isso força o teclado numérico em dispositivos móveis
                      }}
                      id="codigoAutenticacao"
                      onChange={handleChange}
                      placeholder="_ _ _ _ _"
                      label=""
                      name="codigoAutenticacao"
                      autoFocus
                      disabled={loading}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </FormControl>
                )}
              </InputMask>

              {/* <TextField
                margin="normal"
                fullWidth
                id="codigoAutenticacao"
                onChange={handleChange}
                placeholder="_ _ _ _ _"
                label=""
                name="codigoAutenticacao"
                autoFocus
                disabled={loading}
                inputProps={{
                  maxLength: 5,
                }}
              /> */}
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{ mt: 3, mb: 2, borderRadius: 2 }}
                />
              ) : (
                <ButtonCustom
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.Button}
                  onClick={handleSubmit}
                  sx={{ mt: 3, mb: 2, p: 1, width: "100%" }}
                >
                  Confirmar
                </ButtonCustom>
              )}
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{ mt: 3, mb: 2, borderRadius: 2 }}
                />
              ) : (
                <ButtonAcaoGrande
                  fullWidth
                  variant="contained"
                  disabled={counter !== 0}
                  Handle={handlerSolicitaCodigoNovamente}
                  sx={{ mt: "10rem !important", mb: 2, opacity: 0.8 }}
                >
                  Solicitar novamente {counter > 0 ? "(" + counter + ")" : ""}
                </ButtonAcaoGrande>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Grid
          item
          md={1}
          sm={1}
          xs={false}
          sx={{
            mx: 5,
          }}
        ></Grid>
      </Hidden>
    </>
    // </Modal>
  );
}
