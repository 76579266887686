import { Card, CardContent } from "@mui/material"

const CardCustom = ({ children }) => {
    return (
        <Card style={{ borderRadius: 2, minHeight: 'auto', maxWidth: '70vh', boxShadow: 'none' }}>
            <CardContent
                sx={{
                    maxHeight: '80vh',
                    background: '#e6e6e6',
                    overflow: 'auto'
                }}
            >
                {children}

            </CardContent>
        </Card>
    )
}

export default CardCustom;