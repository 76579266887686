import { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { NOTIFICATION } from "../../util/typesReactQuery";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificacaoCliente,
  setNotificacaoClientelido,
  getNotificacoesNaoLidasCliente,
} from "../../api/Notificacao";
import { Link } from "react-router-dom";
import { formatDistance, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import useStyles from "./styles";
import useNotificationsNaoLidas from "../../hooks/useNotifications/useNotificationsNaoLida";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: 'white'
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    maxWidth: 370,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Notificacao() {
  const { user } = useAuth();
  const {
    Alert,
    showAlertSuccess: showSuccess,
    showAlertError: showError,
  } = useAlert();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [notificacaoData, setNotificacaoData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { notificacoesNaoLidas, refetchNotificacoesNaoLidas, quantidadeNotificacoes, setMarcarComoLida, errorNotNaoLida } = useNotificationsNaoLidas();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    refetchNotificacoesNaoLidas();
  }, [])

  useEffect(() => {
    setNotificacaoData((prevState) => notificacoesNaoLidas);
  }, [notificacoesNaoLidas])


  const handlerRedirectPage =
    (idNotificacao) => (event) => {
      event.preventDefault();
      setMarcarComoLida(idNotificacao)

    };

  useEffect(() => {
    if (!!errorNotNaoLida)
      console.error("Erro na Notificacao: ", errorNotNaoLida)

  }, [errorNotNaoLida,])




  return (
    <div style={{ alignItems: "center", marginRight: 20 }}>
      <Alert />
      <StyledBadge
        className={classes.Badge}
        badgeContent={quantidadeNotificacoes >= 5 ? "+5" : quantidadeNotificacoes}
        color="primary"
      >
        <NotificationsNoneIcon
          style={{ cursor: "pointer" }}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          className={classes.customIconButton}
          onClick={notificacaoData ? handleClick : null}
        // endIcon={<KeyboardArrowDownIcon />}
        ></NotificationsNoneIcon>
      </StyledBadge >
      <Box>
        <StyledMenu
          className={classes.Menu}
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={notificacaoData ? open : false}
          onClose={handleClose}
        >
          {!!notificacaoData?.length ? (
            notificacaoData.map(
              (notif, index) =>
                <div key={notif.id}>
                  <MenuItem
                    index={index}
                    className={classes.MenuItem}
                    onClick={handlerRedirectPage(
                      notif.id,
                      notif.tipoNotificacaoCliente.id
                    )}
                    disableRipple
                  >
                    <Typography
                      className={classes.TitleNotificacao}
                      variant="body1"
                      noWrap
                    >
                      {notif.titulo}
                    </Typography>
                    <Typography variant="subtitle1" noWrap>
                      {notif.texto}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                     {" "}
                      {formatDistanceToNow(
                        new Date(notif.dataCadastro),
                        {
                          locale: ptBR, addSuffix: true
                        }
                      )}
                    </Typography>
                  </MenuItem>
                  <Divider />
                </div>
            )

          ) : (
            <MenuItem className={classes.MenuItem} onClick={handleClose}>
              <Typography
                className={classes.TitleNotificacao}
                variant="body1"
                noWrap
              >
                Sem notificações recentes
              </Typography>
              <Typography variant="subtitle1" noWrap>
                {" "}
                Não há notificações no momento
              </Typography>
            </MenuItem>
          )}
          <Link to="/Notificacoes">
            <MenuItem className={classes.MenuItem} onClick={handleClose}>
              <Typography color="primary">Ver notificações antigas</Typography>
            </MenuItem>
          </Link>
        </StyledMenu>
      </Box>
    </div>
  );
}
